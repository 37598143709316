import React, { useState, useRef, useCallback, KeyboardEvent, DragEvent, useEffect } from 'react';
import { Mic, Upload, X, Loader2, Trash2, ArrowUpIcon } from 'lucide-react';
import { useChatGPT } from '../providers';
import { defaultStyles, FileIcon } from 'react-file-icon';
import { StreamIcon } from './Icon';
import ReactDOM from 'react-dom/client';
import { useAuth, useCredit } from '../../../providers';
import { SVGIcon } from '../../../assets';
import { allowedTypes, AttachmentComponent, TextComponent } from '../hooks/useMultiModalConfig';
import { useMultiModal } from '../providers/MultiModalProvider';


export const ChatGPTBottomMultidocs: React.FC = () => {
    //const [contents, setContents] = useState<ContentItem[]>([]);
    //const [isEmpty, setIsEmpty] = useState(true);

    const [isFileUploadLoading, setIsFileUploadLoading] = useState<boolean>(false);
    //const [isRecording, setIsRecording] = useState(false);
    //const [isTranscriptLoading, setIsTranscriptLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    //const fileInputRef = useRef<HTMLInputElement>(null);
    //const editableRef = useRef<HTMLDivElement>(null);
    //const mediaRecorderRef = useRef<MediaRecorder | null>(null);
    //const mediaStreamRef = useRef<MediaStream | null>(null);

    const { config } = useMultiModal()

    const { remainingCredit, setRemainingCredit } = useCredit()

    const {
        gptInput,
        gptGesture,
        homeStatus,
        setHomeStatus,
        chatStatus,
        gptConfig,
        setModalStatus
    } = useChatGPT();

    const { token } = useAuth();

    useEffect(() => {
        const b: boolean = config.contents.some(contents => contents.type == "attachment" && !contents.uri);
        setIsFileUploadLoading(b)
    }, [config.contents])

    const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            if (homeStatus) {
                setHomeStatus(false)
            }
            handleSubmit(e);
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (isFileUploadLoading || config.isRecording || config.isTranscriptLoading) {
            return;
        }

        if (!config.editableRef.current?.textContent?.trim() && !config.isRecording) return;


        const htmlContent = config.editableRef.current?.innerHTML || "";
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, "text/html");

        // Supprimer les éléments avec la classe "trash-div-element"
        const trashDivs = doc.querySelectorAll(".trash-div-element");
        trashDivs.forEach((trashDiv) => trashDiv.remove());

        // Modifier les éléments ayant contentEditable à true
        const editableElements = doc.querySelectorAll("[contenteditable='true']");
        editableElements.forEach((element) => {
            element.setAttribute("contenteditable", "false");
        });

        // Récupérer le HTML final
        const final_html = doc.body.innerHTML;
        setIsLoading(true);
        gptInput.updateConversation(
            gptConfig.selectedChatId,
            { data_name: null, data_type: "html", role: "user", content: final_html, user: final_html, model: "ia_multidocs" },
            "add",
            gptConfig.setSecuredGPTHistory
        )
        gptInput.updateConversation(
            gptConfig.selectedChatId,
            { data_name: null, data_type: "text", role: "assistant", content: "", assistant: "", model: "ia_multidocs", loading: true },
            "add",
            gptConfig.setSecuredGPTHistory
        )

        try {
            const formData = new FormData();

            // Add session ID and HTML
            formData.append('sessionId', gptConfig.selectedChatId);
            formData.append('html', final_html);

            // Handle texts
            const extracts_texts: string[] = extractContent();
            const texts = extracts_texts.filter(item => item.trim() !== "");

            console.log(texts)
            formData.append('texts', JSON.stringify(texts));

            config.editableRef.current!.innerHTML = '';
            // Replace '/your-fastapi-endpoint' with your actual endpoint URL
            const response = await fetch('/api/multidocs_chat/ask', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                body: formData,
            });

            if (response.ok && response.body) {
                const reader = response.body.getReader();
                const decoder = new TextDecoder();
                let done = false;
                let accumulatedText = '';

                let last = 0;
                while (!done && last != 1) {
                    const { value, done: doneReading } = await reader.read();
                    done = doneReading;
                    if (value) {
                        const chunk = decoder.decode(value, { stream: true });
                        if (chunk.startsWith("credit_event_stream_vinci_sdh")) {
                            const parts = chunk.split("##");

                            parts.forEach(part => {
                                if (part.startsWith("credit_event_stream_vinci_sdh")) {
                                    const creditValue = parseFloat(part.split(": ")[1]);
                                    if (remainingCredit && creditValue) {
                                        const new_credit = remainingCredit - creditValue;
                                        setRemainingCredit(new_credit);
                                    }
                                } else if (part.startsWith("res_title_vinci_sdh")) {
                                    const newTitle = part.split(": ").slice(1).join(": ");
                                    gptConfig.setSecuredGPTHistory(prevHistory => ({
                                        ...prevHistory,
                                        [gptConfig.selectedChatId]: {
                                            ...prevHistory[gptConfig.selectedChatId],
                                            title: newTitle,
                                        }
                                    }));
                                }
                            });
                        } else {
                            accumulatedText += chunk;
                        }
                    }
                    gptInput.updateConversation(
                        gptConfig.selectedChatId,
                        accumulatedText,
                        "update",
                        gptConfig.setSecuredGPTHistory,
                        !done
                    )
                    if (done)
                        last = 1
                }
                setIsLoading(false);
            } else {
                gptInput.updateConversation(
                    gptConfig.selectedChatId,
                    { data_name: null, data_type: "text", role: "error", content: "An Error has occurred", error: "An Error has occurred", model: 'multimodal' },
                    "add",
                    gptConfig.setSecuredGPTHistory
                )
                setIsLoading(false);
            }

        } catch (error) {
            gptInput.updateConversation(
                gptConfig.selectedChatId,
                { data_name: null, data_type: "text", role: "error", content: "An Error has occurred", error: "An Error has occurred", model: 'multimodal' },
                "add",
                gptConfig.setSecuredGPTHistory
            )
            console.error('Error sending message:', error);
        } finally {
            setIsLoading(false);
        }
    };

    function extractContent(): string[] {
        const editable = config.editableRef.current;
        if (!editable) return [];

        const children = Array.from(editable.childNodes);
        const texts: string[] = [];
        let currentText = '';

        children.forEach(node => {
            console.log(node, node.nodeType)
            if (node.nodeType === Node.TEXT_NODE) {
                currentText += node.textContent || '';
            } else if (node.nodeType === Node.ELEMENT_NODE) {
                const element = node as HTMLElement;
                if (element.getAttribute('data-id') !== null) {
                    if (currentText.trim() !== '') {
                        texts.push(currentText.trim());
                        currentText = '';
                    }
                    const attachmentId = element.getAttribute('data-id');
                    const attachment = config.contents.find(a => a.id === attachmentId);
                    if (attachment?.type == "attachment" && attachment.uri) {
                        texts.push(attachment.uri);
                    } else if (attachment?.type == "text" && attachment.text) {
                        texts.push(attachment.text);
                    }
                } else if (element.textContent && element.textContent?.length > 0) {
                    texts.push(element.textContent);
                }
            }
        });

        if (currentText.trim() !== '') {
            texts.push(currentText.trim());
        }

        return texts;
    }

    return (
        <div className={`md:pt-0 dark:border-white/20 md:border-transparent md:dark:border-transparent w-full ${chatStatus == "ASSISTANT_OPEN" && "sticky bottom-0 px-4 my-2"}`}>
            {!gptGesture.isAtBottom && !homeStatus && (
                <button
                    onClick={gptGesture.scrollToEndOfMessages}
                    className="cursor-pointer absolute z-20 rounded-full border right-1/2 translate-x-1/2 bg-white"
                    style={{ bottom: `calc(${Math.max(gptInput.chatGPTInputPosition.height, 80)}px + 10px)` }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" className="icon-md m-1 text-token-text-primary">
                        <path fill="currentColor" fillRule="evenodd" d="M12 21a1 1 0 0 1-.707-.293l-7-7a1 1 0 1 1 1.414-1.414L11 17.586V4a1 1 0 1 1 2 0v13.586l5.293-5.293a1 1 0 0 1 1.414 1.414l-7 7A1 1 0 0 1 12 21" clipRule="evenodd"></path>
                    </svg>
                </button>
            )}

            <div ref={gptInput.inputRef} className="mx-auto flex flex-col flex-1 gap-4 text-base md:gap-5 lg:gap-6 md:max-w-3xl lg:max-w-[40rem] xl:max-w-[48rem]">
                <div className="flex flex-col items-center">
                    <div className="flex flex-col w-full p-1 bg-gray-100 rounded-[26px] gap-2">
                        <form onSubmit={handleSubmit} className="flex flex-col">
                            <div
                                ref={config.editableRef}
                                contentEditable
                                onInput={config.handleInput}
                                onBlur={config.handleInput}
                                onFocus={() => config.isEmpty && config.setIsEmpty(false)}
                                onKeyDown={handleKeyDown}
                                onDrop={config.handleDrop}
                                className={`flex-1 px-4 py-3 min-h-[80px] max-h-[300px] overflow-y-auto outline-none empty:before:content-[attr(data-placeholder)] empty:before:text-gray-400 gap-8 ${config.isEmpty ? "empty" : ""}`}
                                data-placeholder="Write your message, insert media, record your prompt here..."
                                aria-label="Message input"
                            >
                                {config.contents.map(content => (
                                    <>
                                        <React.Fragment key={content.id}>
                                            {content.type === 'text' ? (
                                                <TextComponent
                                                    content={content}
                                                    onUpdate={config.updateText}
                                                />
                                            ) : (
                                                <AttachmentComponent
                                                    content={content}
                                                    onDelete={config.deleteContent}
                                                />
                                            )}
                                            <span
                                                contentEditable
                                                suppressContentEditableWarning
                                            >
                                                {'\u00A0'}
                                            </span>
                                        </React.Fragment>
                                    </>
                                ))}
                            </div>

                            <div className="flex items-center justify-between gap-2 px-2 py-2 border-t border-gray-200">
                                <div className="flex items-center gap-1">
                                    <input
                                        type="file"
                                        ref={config.fileInputRef}
                                        onChange={config.handleFileUpload}
                                        className="hidden"
                                        multiple
                                        accept={allowedTypes.join(",")}
                                    />

                                    <button
                                        type="button"
                                        onClick={() => config.fileInputRef.current?.click()}
                                        className="p-2 hover:bg-gray-200 rounded-lg transition-colors"
                                        disabled={isLoading}
                                    >
                                        <Upload className="w-5 h-5 text-gray-600" />
                                    </button>

                                    <button
                                        type="button"
                                        onClick={config.toggleRecording}
                                        className={`p-2 rounded-lg transition-colors ${config.isRecording ? 'bg-red-100 text-red-500' : 'hover:bg-gray-200'
                                            }`}
                                        disabled={isLoading}
                                    >
                                        {config.isRecording ? (
                                            <X className="w-5 h-5" />
                                        ) : (
                                            <Mic className="w-5 h-5 text-gray-600" />
                                        )}
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => {setModalStatus("MULTIMODAL")}}
                                        className="p-2 hover:bg-gray-200 rounded-lg transition-colors text-gray-600"
                                        disabled={isLoading}
                                    >
                                        <SVGIcon size="18px" icon="LINKS_ICON" />
                                    </button>

                                </div>

                                {isLoading ? (
                                    <div className="flex items-center justify-center w-8 h-8 bg-black text-[#F4F4F4] rounded-full">
                                        <StreamIcon />
                                    </div>
                                ) : (
                                    <button
                                        type="submit"
                                        className={`flex items-center justify-center ${chatStatus != "ASSISTANT_OPEN" ? "w-8 h-8" : "w-6 h-6"
                                            } bg-black text-[#F4F4F4] rounded-full ${isFileUploadLoading || config.isRecording || config.isTranscriptLoading ? "opacity-30" : "hover:bg-gray-800"}`}
                                    >
                                        <ArrowUpIcon />
                                    </button>
                                )}
                            </div>
                        </form>
                    </div>

                    {chatStatus != "ASSISTANT_OPEN" && (
                        <div className="relative px-2 py-2 text-center text-xs text-token-text-secondary md:px-[60px]">
                            <span>SecuredGPT may make mistakes. Consider checking important information.</span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};