import { ReactComponent as CHARTS_ICON } from './Charts.svg';
import { ReactComponent as DOCUMENT_ICON } from './Document.svg';
import { ReactComponent as HOME_ICON } from './Home.svg';
import { ReactComponent as LABEL_ICON } from './Label.svg';
import { ReactComponent as PLANE_ICON } from './Plane.svg';
import { ReactComponent as PAPER_PLANE_ICON } from './PaperPlane.svg';
import { ReactComponent as RIGHT_CHEVRON_ICON } from "./RightChevron.svg";
import { ReactComponent as LEFT_CHEVRON_ICON } from "./LeftChevron.svg";
import { ReactComponent as TOP_CHEVRON_ICON } from "./TopChevron.svg";
import { ReactComponent as DOWN_CHEVRON_ICON } from "./DownChevron.svg";
import { ReactComponent as SMALL_VINCI_ICON } from "./SmallVinci.svg";
import { ReactComponent as BIG_VINCI_ICON } from "./BigVinci.svg";
import { ReactComponent as CLOSE_ICON } from "./Close.svg";
import { ReactComponent as ROSE_ICON } from "./Logo SDH BU Rose.svg"
import { ReactComponent as VERT_ICON } from "./Logo SDH BU Vert.svg"
import { ReactComponent as BLEU_ICON } from "./Logo SDH HQ Bleu.svg"
import { ReactComponent as IA_CHAT_ICON } from "./ChatIconIA.svg"
import { ReactComponent as IA_CHAT_HOME_ICON } from "./ChatIconIAHome.svg"
import { ReactComponent as IA_CHAT_MULTIDOC } from "./ChatIconIAMultiDoc.svg"
import { ReactComponent as IA_CHAT_OAG } from "./ChatIconOAG.svg"
import { ReactComponent as GPT_CHAT_ICON } from "./ChatIconGPT.svg"
import { ReactComponent as GPT_CHAT_ICON_XL } from "./ChatIconGPTXL.svg"
import { ReactComponent as USER_CHAT_ICON } from "./ChatIconUser.svg"
import { ReactComponent as REFRESH_ICON } from "./Refresh.svg"
import { ReactComponent as MENU_DOTS_ICON } from "./MenuDots.svg"
import { ReactComponent as MENU_RADIUS_ICON } from "./Radius.svg"
import { ReactComponent as MENU_LINE_ICON } from "./Line.svg"
import { ReactComponent as DASHBOARD_HQ_ICON } from "./DashboardHQ.svg"
import { ReactComponent as DASHBOARD_BU_ICON } from "./DashboardBU.svg"
import { ReactComponent as TRAFFIC_BU_ICON } from "./HomeSDHBU1.svg"
import { ReactComponent as RETAIL_BU_ICON } from "./HomeSDHBU2.svg"
import { ReactComponent as FINANCE_BU_ICON } from "./HomeSDHBU3.svg"
import { ReactComponent as AIRLINEID_BU_ICON } from "./HomeSDHBU4.svg"
import { ReactComponent as SECURE_CHAT_ICON } from "./secureChat.svg"
import { ReactComponent as NEWSLETTER_ICON } from "./newsletter.svg"
import { ReactComponent as TRANSLATOR_ICON } from "./translator.svg"
import { ReactComponent as SENTIMENT_ANALYSIS_ICON } from "./sentimentAnalysis.svg"
import { ReactComponent as DATA_ROOM_ICON } from "./dataRoom.svg"
import { ReactComponent as CUSTOM_TRAFFIC_ICON } from "./customTraffic.svg"
import { ReactComponent as CUSTOM_FINANCE_ICON } from "./customFinance.svg"
import { ReactComponent as COMPETITION_ANALYSIS_ICON } from "./competitionAnalysis.svg"
import { ReactComponent as ARROW_BLACK_ICON } from "./arrowBlack.svg"
import { ReactComponent as LOGOUT_ICON } from "./logout.svg"
import { ReactComponent as ENGLISH_ICON } from "./english.svg"
import { ReactComponent as LANGUAGE_ARROW_ICON } from "./languageArrow.svg"
import { ReactComponent as CAR_PARKS_ICON } from "./Parking.svg"
import { ReactComponent as COMMUNICATION_ICON } from "./Communication.svg"
import { ReactComponent as ARROW_SMALL_ICON } from "./arrowSmall.svg"
import { ReactComponent as SIZE_SCREEN_ICON } from "./sizeScreen.svg"
import { ReactComponent as DOWNLOAD_ICON } from "./download.svg"
import { ReactComponent as LINKS_ICON } from "./linksOAG.svg"
import { ReactComponent as UPLOAD_ICON } from "./uploadOAG.svg"
import { ReactComponent as SHAREPOINT_ICON } from "./Sharepoint.svg"
import { ReactComponent as HELP_ICON } from "./help-circle.svg"
import { ReactComponent as OPERATIONS_ICON } from "./OPS.svg"
import { ReactComponent as BUG_ICON } from "./Bug.svg"
import { ReactComponent as MISTRAL_CHAT_ICON } from "./ChatIconMistral.svg"
import { ReactComponent as GEMINI_CHAT_ICON } from "./Gemini.svg"
import { ReactComponent as GPT4_CHAT_ICON } from "./ChatIconGPT4.svg"
import { ReactComponent as MISTRAL_CHAT_ICON_XL } from "./ChatIconMistralXL.svg"
import { ReactComponent as GEMINI_CHAT_ICON_XL } from "./GeminiXL.svg"
import { ReactComponent as GPT4_CHAT_ICON_XL } from "./ChatIconGPT4XL.svg"
import { ReactComponent as DATA_GOUVERNANCE_ICON } from "./data-gouv.svg"
import { ReactComponent as CLOUD_UPLOAD_ICON } from "./cloud-upload.svg"
import { ReactComponent as LINE_STEP_ICON } from "./LineStep.svg"
import { ReactComponent as CHECK_FILE_ICON } from "./checkFile.svg"
import { ReactComponent as CHECK_KPIS_ICON } from "./checkKpis.svg"
import { ReactComponent as FILE_PROCESSED_ICON } from "./file-processed.svg"
import { ReactComponent as CLOUD_UPLOAD_ICON_GREEN } from "./cloud-upload-green.svg"
import { ReactComponent as LINE_STEP_ICON_GREEN } from "./LineStepGreen.svg"
import { ReactComponent as CHECK_FILE_ICON_GREEN } from "./checkFile-green.svg"
import { ReactComponent as CHECK_KPIS_ICON_GREEN } from "./checkKpis-green.svg"
import { ReactComponent as FILE_PROCESSED_ICON_GREEN } from "./file-processed-green.svg"
import { ReactComponent as TRASH_ICON } from './trashIcon.svg';
import { ReactComponent as FOLDER_DOWNLOAD_ICON } from './folder-download.svg';
import { ReactComponent as TRAFFIC_ICON } from './trafficIcon.svg';
import { ReactComponent as OPS_ICON } from './opsIcon.svg';
import { ReactComponent as RETAIL_ICON } from './retailIcon.svg';
import { ReactComponent as FINANCE_ICON } from './financeIcon.svg';
import { ReactComponent as HR_ICON } from './HRIcon.svg';
import { ReactComponent as COM_ICON } from './comIcon.svg'
import { ReactComponent as EXTERNAL_ICON } from './external.svg';
import { ReactComponent as CARPARK_ICON } from './carparkIcon.svg';
import { ReactComponent as PREDICT_ICON } from './predictIcon.svg';







export const SVGIcons = {
    CHARTS_ICON: CHARTS_ICON,
    DOCUMENT_ICON: DOCUMENT_ICON,
    HOME_ICON: HOME_ICON,
    LABEL_ICON: LABEL_ICON,
    PLANE_ICON: PLANE_ICON,
    PAPER_PLANE_ICON: PAPER_PLANE_ICON,
    RIGHT_CHEVRON_ICON: RIGHT_CHEVRON_ICON,
    LEFT_CHEVRON_ICON: LEFT_CHEVRON_ICON,
    TOP_CHEVRON_ICON: TOP_CHEVRON_ICON,
    DOWN_CHEVRON_ICON: DOWN_CHEVRON_ICON,
    SMALL_VINCI_ICON: SMALL_VINCI_ICON,
    BIG_VINCI_ICON: BIG_VINCI_ICON,
    CLOSE_ICON: CLOSE_ICON,
    ROSE_ICON: ROSE_ICON,
    VERT_ICON: VERT_ICON,
    BLEU_ICON: BLEU_ICON,
    REFRESH_ICON: REFRESH_ICON,
    MENU_DOTS_ICON: MENU_DOTS_ICON,
    MENU_RADIUS_ICON: MENU_RADIUS_ICON,
    MENU_LINE_ICON: MENU_LINE_ICON,
    DASHBOARD_HQ_ICON: DASHBOARD_HQ_ICON,
    DASHBOARD_BU_ICON: DASHBOARD_BU_ICON,
    TRAFFIC_BU_ICON : TRAFFIC_BU_ICON,
    RETAIL_BU_ICON: RETAIL_BU_ICON,
    FINANCE_BU_ICON: FINANCE_BU_ICON,
    AIRLINEID_BU_ICON: AIRLINEID_BU_ICON,
    SECURE_CHAT_ICON: SECURE_CHAT_ICON,
    NEWSLETTER_ICON: NEWSLETTER_ICON,
    TRANSLATOR_ICON: TRANSLATOR_ICON,
    SENTIMENT_ANALYSIS_ICON: SENTIMENT_ANALYSIS_ICON,
    DATA_ROOM_ICON: DATA_ROOM_ICON,
    CUSTOM_TRAFFIC_ICON: CUSTOM_TRAFFIC_ICON,
    CUSTOM_FINANCE_ICON: CUSTOM_FINANCE_ICON,
    COMPETITION_ANALYSIS_ICON: COMPETITION_ANALYSIS_ICON,
    ARROW_BLACK_ICON: ARROW_BLACK_ICON,
    LOGOUT_ICON: LOGOUT_ICON,
    ENGLISH_ICON: ENGLISH_ICON,
    LANGUAGE_ARROW_ICON: LANGUAGE_ARROW_ICON,
    CAR_PARKS_ICON: CAR_PARKS_ICON,
    COMMUNICATION_ICON: COMMUNICATION_ICON,
    ARROW_SMALL_ICON: ARROW_SMALL_ICON,
    SIZE_SCREEN_ICON: SIZE_SCREEN_ICON,
    DOWNLOAD_ICON: DOWNLOAD_ICON,
    LINKS_ICON: LINKS_ICON,
    UPLOAD_ICON: UPLOAD_ICON,
    SHAREPOINT_ICON: SHAREPOINT_ICON,
    HELP_ICON: HELP_ICON,
    OPERATIONS_ICON: OPERATIONS_ICON,
    BUG_ICON: BUG_ICON,
    DATA_GOUVERNANCE_ICON: DATA_GOUVERNANCE_ICON,
    CLOUD_UPLOAD_ICON: CLOUD_UPLOAD_ICON,
    LINE_STEP_ICON: LINE_STEP_ICON,
    CHECK_FILE_ICON: CHECK_FILE_ICON,
    FILE_PROCESSED_ICON: FILE_PROCESSED_ICON,
    CHECK_KPIS_ICON: CHECK_KPIS_ICON,
    CLOUD_UPLOAD_ICON_GREEN: CLOUD_UPLOAD_ICON_GREEN,
    LINE_STEP_ICON_GREEN: LINE_STEP_ICON_GREEN,
    CHECK_FILE_ICON_GREEN: CHECK_FILE_ICON_GREEN,
    FILE_PROCESSED_ICON_GREEN: FILE_PROCESSED_ICON_GREEN,
    CHECK_KPIS_ICON_GREEN: CHECK_KPIS_ICON_GREEN,
    TRASH_ICON: TRASH_ICON,
    FOLDER_DOWNLOAD_ICON: FOLDER_DOWNLOAD_ICON,
    TRAFFIC_ICON : TRAFFIC_ICON,
    OPS_ICON: OPS_ICON,
    RETAIL_ICON:RETAIL_ICON,
    FINANCE_ICON:FINANCE_ICON,
    HR_ICON:HR_ICON,
    COM_ICON:COM_ICON,
    EXTERNAL_ICON:EXTERNAL_ICON,
    CARPARK_ICON:CARPARK_ICON,
    PREDICT_ICON:PREDICT_ICON,
    
}

export const ChatLogoIcon = {
    IA_CHAT_ICON: IA_CHAT_ICON,
    IA_CHAT_HOME_ICON: IA_CHAT_HOME_ICON,
    IA_CHAT_MULTIDOC: IA_CHAT_MULTIDOC,
    IA_CHAT_OAG: IA_CHAT_OAG,
    GPT_CHAT_ICON: GPT_CHAT_ICON,
    GPT_CHAT_ICON_XL: GPT_CHAT_ICON_XL,
    USER_CHAT_ICON: USER_CHAT_ICON,
    GPT4_CHAT_ICON: GPT4_CHAT_ICON,
    MISTRAL_CHAT_ICON: MISTRAL_CHAT_ICON,
    GEMINI_CHAT_ICON: GEMINI_CHAT_ICON,
    GPT4_CHAT_ICON_XL: GPT4_CHAT_ICON_XL,
    MISTRAL_CHAT_ICON_XL: MISTRAL_CHAT_ICON_XL,
    GEMINI_CHAT_ICON_XL: GEMINI_CHAT_ICON_XL
}