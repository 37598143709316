import { CheckFile, CloudUpload, LineStep, CheckKPis, FileProcessed } from "./Icons";

export interface FileStepStatus {
  status: 'pending' | 'running' | 'finished' | 'error';
}

export interface FileStatusRefProps {
  status: {
    loaded: string;
    checkfile: string;
    processed: string;
  };
}

const getStatusColor = (status: string) => {
  switch (status) {
    case 'running':
      return '#F78000';
    case 'finished':
      return '#00BE00';
    case 'error':
      return '#E61A23';
    default:
      return '#686868';
  }
};

export const FileStatusReferentiel: React.FC<FileStatusRefProps> = ({ status }) => {
  return (
    <div className="flex flex-row gap-1 scale-[1.1] items-center">
      <CloudUpload fillColor={getStatusColor(status.loaded)} />

      <LineStep fillColor={getStatusColor(status.checkfile)} />

      <CheckFile fillColor={getStatusColor(status.checkfile)} />

      <LineStep fillColor={getStatusColor(status.processed)} />

      <FileProcessed fillColor={getStatusColor(status.processed)} />
    </div>
  );
};