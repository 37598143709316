import { createContext, useContext } from "react";
import { MultiModalConfig, useMultiModalConfig } from "../hooks/useMultiModalConfig";


interface MultiDocsContextType {
  config: MultiModalConfig;
}

const MultiDocsContext = createContext<MultiDocsContextType | undefined>(undefined);


export const MultiModalProvider = ({ children }: React.PropsWithChildren) => {
  const config = useMultiModalConfig()

  return (
    <MultiDocsContext.Provider value={{
      config,
    }}>
      {children}
    </MultiDocsContext.Provider>
  );
};

export const useMultiModal = () => {
  const context = useContext(MultiDocsContext);
  if (!context) {
    throw new Error('useMultiModal must be used within a MultiModalProvider');
  }
  return context;
};