import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAuth } from '../../providers';

export const Download = () => {
    const { isAuthenticated, token } = useAuth();
    const [searchParams] = useSearchParams();
    const uri = searchParams.get("uri") || "";
    const [status, setStatus] = useState<'idle' | 'downloading' | 'complete' | 'error'>('idle');
    const [errorMessage, setErrorMessage] = useState<string>("");

    useEffect(() => {
        const executeFetchDoc = async () => {
            if (!isAuthenticated || !token) return;
            if (!uri) {
                setStatus('error');
                setErrorMessage("No URI provided");
                return;
            }

            try {
                setStatus('downloading');
                const fileUri = getFileNameFromUrl(uri);
                const response = await fetch(`/download?uri=${encodeURIComponent(uri)}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (!response.ok) {
                    throw new Error(`Failed to download the file: ${response.statusText}`);
                }

                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = fileUri;
                document.body.appendChild(a);
                a.click();
                
                // Cleanup
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
                
                setStatus('complete');
                
                // Fermer la fenêtre après un court délai pour s'assurer que le téléchargement a commencé
                setTimeout(() => {
                    window.close();
                }, 2000);
                
            } catch (error) {
                console.error("Error downloading the file:", error);
                setStatus('error');
                setErrorMessage(error instanceof Error ? error.message : "Unknown error occurred");
            }
        };

        executeFetchDoc();
    }, [isAuthenticated, token, uri]);

    const getFileNameFromUrl = (url: string) => {
        const urlParts = url.split('/');
        return urlParts[urlParts.length - 1] || 'doc.docx';
    };

    if (!isAuthenticated) {
        return (
            <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
                <h1 className="text-4xl font-bold text-red-600">403</h1>
                <p className="text-xl text-gray-800">Unauthorized</p>
                <p className="text-gray-600 mt-4">
                    You do not have permission to access this page.
                </p>
            </div>
        );
    }

    return (
        <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
            {status === 'idle' && (
                <p className="text-gray-600">Preparing download...</p>
            )}
            
            {status === 'downloading' && (
                <div className="text-center">
                    <p className="text-blue-600">Starting download...</p>
                    <p className="text-sm text-gray-500 mt-2">This window will close automatically</p>
                </div>
            )}
            
            {status === 'complete' && (
                <div className="text-center">
                    <p className="text-green-600">Download started!</p>
                    <p className="text-sm text-gray-500 mt-2">Closing window...</p>
                </div>
            )}
            
            {status === 'error' && (
                <div className="text-center">
                    <h1 className="text-4xl font-bold text-red-600">Error</h1>
                    <p className="text-gray-800 mt-4">{errorMessage}</p>
                    <p className="text-gray-600 mt-2">
                        Please try again or contact support if the problem persists.
                    </p>
                </div>
            )}
        </div>
    );
};

export default Download;