type Listener = () => void;

let listeners: Listener[] = [];
let state: string | null = null;

// Récupère la session depuis l'API
export async function fetchSession(sessionId: string, token: string, app: string): Promise<void> {
  const response = await fetch(`/api/${app}/get-session/${sessionId}`, {
    method: "GET",
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  const data = await response.json();
  state = data.start_time;
  console.log("Updated state:", state, data.start_time); // Debug
  notifySubscribers();
}

// Notifie tous les abonnés
function notifySubscribers(): void {
  listeners.forEach((listener) => listener());
}

// Abonne un écouteur aux changements d'état
export function subscribe(listener: Listener): () => void {
  listeners.push(listener);
  return () => {
    listeners = listeners.filter((l) => l !== listener);
  };
}

// Retourne l'état actuel
export function getSnapshot(): string | null {
  return state;
}

// Crée une nouvelle session
export async function createSession(token: string, app: string): Promise<string> {
  const response = await fetch(`/api/${app}/create-session`, {
    method: "POST",
  });
  const data = await response.json();
  await fetchSession(data.uuid, token, app);
  return data.uuid;
}

// Supprime une session
export async function deleteSession(sessionId: string, app: string): Promise<void> {
  await fetch(`/api/${app}/delete-session/${sessionId}`, {
    method: "DELETE",
  });
}
