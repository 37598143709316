import { FileLoaderReferentiel } from "./components/FileLoaderReferentiel"
import { FileChecksReferentiel } from "./components/FileChecksReferentiel"

export const Referentiel = () => {
    return(
        <div className="flex flex-col w-full bg-white p-3 rounded-xl items-center text-text-primary-light gap-5">
            <FileLoaderReferentiel/>
            <FileChecksReferentiel/>
        </div>
    )
}