import { useState } from "react";

export interface FileStatus {
  loaded: string;
  checkfile: string;
  checkKpis: string;
  processed: string;
}

interface ErrorIngestion {
  title: string;
  description: string
}

export interface KPIsData {
  [key: string]: Array<Array<string | number>>;
}

export interface FileData {
  file_type: string;
  status: FileStatus;
  error?: ErrorIngestion
  version?: string;
  linked?: string;
  final_filename?:string;
  url?: string;
  landing_url?:string;
  kpis?: KPIsData;
  file_id?: string
  uploaded_by?: string;
  date: string
}

export interface IngestionData {
  [locationCode: string]: FileData[];
}


export const useDataIngestion = (token: string | null) => {
    const [ingestionData, setIngestionData] = useState<IngestionData | null>(null)
    const [loaderIngestionData, setLoaderIngestionData] = useState(false)

   


    const getIngestionData = async (use_case?: string,asset?: string, date?: string) => {
      if (token === null) return;
      setLoaderIngestionData(true)
      const [month, year] = date ? date.split('/') : [undefined, undefined];
    
      try {
        const response = await fetch(`/api/gouvernance/ingestion/data?use_case=${use_case?.toUpperCase()}&asset=${asset}&year=${year}&month=${month}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
    
        if (!response.ok) {
          throw new Error(`Erreur HTTP: ${response.status}`);
        }
    
        const data = await response.json();
        setIngestionData(data);
      } catch (error) {
        console.error('An error occurred while fetching the ingestion data:', error);
        return null
      }
      finally{
        setLoaderIngestionData(false)
      }
    };


    const confirmFile = async(file_id: string) => {
      if(token === null) return
      try{
        const response = await fetch(`api/data_gov/ingestion/validate`,{
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'

          },
          body: JSON.stringify({file_id})
        })

        if (!response.ok) {
          throw new Error(`Erreur HTTP: ${response.status}`);
        }

        const data = await response.json();
        console.log(data)

        return data
      }
      catch(error) {
        console.log(error)
        return null
      }
    }


    
    const uploadFile = async (file_asset: string, file_type: string, file: File, file_version?: string, month?:string, year?: string) => {
        if (token === null) return;

        const formData = new FormData();
        formData.append('file_asset', file_asset);
        formData.append('file_type', file_type);
        formData.append('file', file);
        formData.append('month', month!)
        formData.append('year', year!)
        {file_version && formData.append('file_version', file_version)}

        return fetch(`api/data_gov/ingestion/file`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`
          },
          body: formData
        })
        .then((response) => response.json())
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.error("Error uploading file:", error);
            return null;
        });
    };
    
    return { uploadFile, getIngestionData, ingestionData, setIngestionData, confirmFile, loaderIngestionData };
};