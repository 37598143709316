import { useEffect, useRef, useState } from "react";
import { SearchLineage } from "./components/SearchLineage";
import { Lineage } from "./Lineage";
import { KPIType } from "./Lineage";
import { useDataGouv } from "./providers/DataGouvProvider";

export const DataLineage = () => {
  const { getEdges, getTables } = useDataGouv();
  const [selectedKpi, setSelectedKpi] = useState<string | null>(null);
  const [selectedNode, setSelectedNode] = useState<KPIType | null>(null);
  const { dataLineage } = useDataGouv();
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (selectedKpi) {
      const kpiObject = dataLineage?.find((elem) => elem.kpi_name === selectedKpi);
      const filePath = kpiObject?.file_path;
      if (filePath) {
        getTables(filePath);
        getEdges(filePath);
      }
    }
  }, [selectedKpi]);

  const scrollToTop = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  return (
    <div className="relative w-full h-screen overflow-hidden">
      <div
        ref={scrollContainerRef}
        className="flex flex-col w-full h-full overflow-y-auto bg-white p-4 rounded-xl items-center text-text-primary-light gap-5"
      >
        <SearchLineage
          selectedKpi={selectedKpi}
          setSelectedKpi={setSelectedKpi}
          selectedNode={selectedNode}
        />
        {selectedKpi && (
          <Lineage
            setSelectedNode={setSelectedNode}
            selectedKpi={selectedKpi}
            scrollContainerRef={scrollContainerRef}
          />
        )}
      </div>
      <button
        onClick={scrollToTop}
        className="absolute bottom-4 right-4 bg-white py-2 px-5 text-sm border border-[#D0D5DD] bg-opacity-80 text-[#344054] rounded-xl focus:outline-none cursor-pointer"
      >
        Scroll to Top
      </button>
    </div>
  );
};