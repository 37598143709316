import { useState, useEffect } from 'react';
import { useAuth, useCredit } from '../../../providers';
import { useChatGPT } from '../providers';

export type UploadFileType = "txt" | "pdf" | "pptx" | "docx" | "html";

export type LinkFile = {
    name: string;
    type: "html"
};

export interface UploadFile {
    file: File | LinkFile;
    ocrChecked: boolean;
    summaryChecked: boolean;
    type: UploadFileType;
    processed: boolean;
    selected: boolean
}

export function getUploadFileType(fileType: string): UploadFileType {
    const uploadFileTypeArr: { [key: string]: UploadFileType } = {
        "text/plain": "txt",
        "application/pdf": "pdf",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation": "pptx",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "docx"
    };

    return uploadFileTypeArr[fileType] || 'html';
}

function getDocuments(uploadFiles: UploadFile[]): { filename: string, type: UploadFileType }[] {
    return uploadFiles
    .filter(fileObj => fileObj.selected)
    .map(fileObj => ({
        filename: fileObj.file.name,
        type: getUploadFileType(fileObj.file instanceof File ? fileObj.file.type : "html")
    }));
}
export interface MultiDocsConfig {
    uploadFiles: UploadFile[];
    setUploadFiles: React.Dispatch<React.SetStateAction<UploadFile[]>>;
    uploadDocuments: () => Promise<void>;
    uploadLink: (link: string, gen_summary: boolean) => Promise<void>;
    askDocuments: (question: string, fileName?: string) => Promise<void>;
    uploadDocumentLoader: boolean;
}

export const useMultiDocsConfig = (multiDocsSessionId: string): MultiDocsConfig => {
    const { token } = useAuth()
    const [uploadFiles, setUploadFiles] = useState<UploadFile[]>([]);
    const { remainingCredit, setRemainingCredit } = useCredit()
    const { gptInput, gptConfig } = useChatGPT()
    const [uploadDocumentLoader, setUploadDocumentLoader] = useState<boolean>(false)
    const {homeStatus, setHomeStatus} = useChatGPT()
    const [error, setError] = useState<Error | null>(null);

    const fetchConfig = async (url: string) => {
        try {
            if (token === null) {
                return;
            }
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) {
                throw new Error(`Erreur HTTP: ${response.status}`);
            }
        } catch (error) {
            console.error('Erreur lors de la récupération de la configuration', error);
            setError(error instanceof Error ? error : new Error('Erreur inconnue'));
        }
    };

    const uploadLink = async (link: string, gen_summary: boolean) => {
        if (homeStatus) {
            setHomeStatus(false)
        }
        setUploadDocumentLoader(true)
        gptInput.updateConversation(
            gptConfig.selectedChatId,
            { data_name: [{ "filename": link, "type": "html" }], data_type: "file_upload", role: "user", content: "Upload", user: "Upload", model: "ia_multidocs" },
            "add",
            gptConfig.setSecuredGPTHistory
        )
        fetch(`/api/multidocs/upload_url`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
                session_id: multiDocsSessionId,
                url: link,
                conversation_id: gptConfig.selectedChatId,
                gen_summary
            })
        })
            .then(response => {
                if (!response.ok) {
                    if (response.status === 500) {
                        console.error("Error uploading link:", link);
                    }
                    return null;
                }
                return response.json();
            })
            .then(data => {
                if (data) {
                    if (data.title && data.title.length > 0) {
                        gptConfig.setSecuredGPTHistory(prevHistory => ({
                            ...prevHistory,
                            [gptConfig.selectedChatId]: {
                                ...prevHistory[gptConfig.selectedChatId],
                                title: data.title,
                            }
                        }));
                    }
                    if (remainingCredit && data.credit) {
                        const new_credit = remainingCredit - data.credit;
                        setRemainingCredit(new_credit);
                    }
                    let selected = false
                    if (uploadFiles.length == 0)
                        selected = true
                    let linkFiles: UploadFile = {
                        file: { name: link, type: "html" },
                        ocrChecked: false,
                        summaryChecked: gen_summary,
                        processed: true,
                        selected: selected,
                        type: "html"
                    };

                    setUploadFiles([...uploadFiles, ...[linkFiles]]);
                    if (data.docs_info && data.docs_info.length > 0) {
                        gptInput.updateConversation(
                            gptConfig.selectedChatId,
                            { data_name: [{ "filename": link, "type": "html" }], data_type: "file_upload", role: "assistant", content: `Here is the summary of ${link}:\n${data.docs_info}`, assistant: `Here is the summary of ${link}:\n${data.docs_info}`, model: "ia_multidocs" },
                            "add",
                            gptConfig.setSecuredGPTHistory
                        )
                    }
                }
                setUploadDocumentLoader(false)
            })
            .catch(error => {
                console.error("Error uploading file:", error);
                setUploadDocumentLoader(false)
            });
    };

    const uploadDocuments = async () => {
        if (homeStatus) {
            setHomeStatus(false)
        }
        setUploadDocumentLoader(true)
        const filesToUpload = uploadFiles.filter(fileObj => !fileObj.processed);

        const uploadPromises = filesToUpload.filter((fileObj) => fileObj.file instanceof File).map((fileObj) => {
            const formData = new FormData();
            formData.append("files_present", "true");
            formData.append('file', fileObj.file as File); // Single file upload
            formData.append("session_id", multiDocsSessionId);
            formData.append("gen_summary", String(fileObj.summaryChecked));
            formData.append("use_extractor_ocr", String(fileObj.ocrChecked));
            formData.append("conversation_id", gptConfig.selectedChatId);
            let fileType = getUploadFileType(fileObj.file.type)
            formData.append("file_type", fileType);
            gptInput.updateConversation(
                gptConfig.selectedChatId,
                { data_name: [{ "filename": fileObj.file.name, "type": fileType }], data_type: "file_upload", role: "user", content: "Upload", user: "Upload", model: "ia_multidocs" },
                "add",
                gptConfig.setSecuredGPTHistory
            )
            return fetch(`/api/multidocs/upload_document`, {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                body: formData,
            })
                .then(response => {
                    if (!response.ok) {
                        if (response.status === 500) {
                            console.error("Error uploading file:", fileObj.file.name);
                        }
                        return null;
                    }
                    return response.json();
                })
                .then(data => {
                    if (data) {
                        if (data.title && data.title.length > 0) {
                            gptConfig.setSecuredGPTHistory(prevHistory => ({
                                ...prevHistory,
                                [gptConfig.selectedChatId]: {
                                    ...prevHistory[gptConfig.selectedChatId],
                                    title: data.title,
                                }
                            }));
                        }
                        if (remainingCredit && data.credit) {
                            const new_credit = remainingCredit - data.credit;
                            setRemainingCredit(new_credit);
                        }
                        setUploadFiles(prevFiles => prevFiles.map(f =>
                            f.file.name === fileObj.file.name ? { ...f, processed: true } : f
                        ));
                        if (data.docs_info && data.docs_info.length > 0) {
                            gptInput.updateConversation(
                                gptConfig.selectedChatId,
                                { data_name: [{ "filename": fileObj.file.name, "type": fileType }], data_type: "file_upload", role: "assistant", content: `Here is the summary of ${fileObj.file.name}:\n${data.docs_info}`, assistant: `Here is the summary of ${fileObj.file.name}:\n${data.docs_info}`, model: "ia_multidocs" },
                                "add",
                                gptConfig.setSecuredGPTHistory
                            )
                        }
                    }
                    setUploadDocumentLoader(false)
                })
                .catch(error => {
                    setUploadDocumentLoader(false)
                    console.error("Error uploading file:", error);
                });
        });

        await Promise.all(uploadPromises);
        setUploadDocumentLoader(false)
    };

    const askDocuments = async (question: string) => {
        try {
            let documents = getDocuments(uploadFiles)
            if (documents.length == 0)
                return;
            let payload: any = {
                id: multiDocsSessionId,
                question: question,
                conversation_id: gptConfig.selectedChatId
            }
            if (documents.length == 1) {
                payload["specific_doc"] = documents[0].filename
            }
            gptInput.updateConversation(
                gptConfig.selectedChatId,
                { data_name: documents, data_type: "file_ask", role: "user", content: question, user: question, model: "ia_multidocs" },
                "add",
                gptConfig.setSecuredGPTHistory
            )
            gptInput.updateConversation(
                gptConfig.selectedChatId,
                { data_name: null, data_type: "text", role: "assistant", content: "", assistant: "", model: "ia_multidocs", loading: true },
                "add",
                gptConfig.setSecuredGPTHistory
            )

            const response = await fetch(
                `/api/multidocs/ask_question`,
                {
                    method: "POST",
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json'
                    },
                    body: JSON.stringify(payload)
                }
            );

            const data = await response.json()
            if (remainingCredit && data.credit) {
                const new_credit = remainingCredit - data.credit;
                setRemainingCredit(new_credit);
            }

            if (data.answer && data.answer.answer) {
                gptInput.updateConversation(
                    gptConfig.selectedChatId,
                    data.answer.answer,
                    "update",
                    gptConfig.setSecuredGPTHistory,
                    false
                )
            }

        }
        catch (error) {
            console.log(error)
        }
        return;
    }

/*     useEffect(() => {
        if (multiDocsSessionId)
            fetchConfig(`/api/multidocs/config/${multiDocsSessionId}`);
    }, [multiDocsSessionId]) */

    return {
        uploadFiles,
        setUploadFiles,
        uploadDocuments,
        uploadLink,
        askDocuments,
        uploadDocumentLoader
    };
};

