import { useEffect, useRef } from "react";
import { useChatGPT } from "../providers";
import { useActiveTabs } from "../../../providers";
import { isSection, Section, Tabs } from "../../../assets";

export const ChatGPTExplore = ({ }: {}) => {

    const { menu, openTab } = useActiveTabs()
    const chatAccess = menu && menu.length > 0 && menu.find(e => e.title == "SecuredGPT" || e.title == "ChatBot_SDH") != undefined

    const sectionRefs = useRef<(HTMLDivElement | null)[]>([]);
    const topRef = useRef<HTMLDivElement>(null);

    const scrollToSection = (index: number) => {
        sectionRefs.current[index]?.scrollIntoView({ behavior: 'smooth' });
    };

    const descriptionStyle = "text-text-primary-light opacity-50"

    const items = [
        {
            title: "Dashboards",
            description: "Visualize key insights and trends with interactive dashboards.",
            layer: <div className="flex flex-col gap-4 pt-4">
                {
                    menu?.map((section: Section | Tabs, elemIndex) => {
                        return (
                            <div key={elemIndex}>
                                {isSection(section) && (section.title == "Dashboards HQ" || section.title == "Dashboards BU") &&
                                    <div className="flex flex-col" key={elemIndex}>
                                        <div className="flex items-center gap-2 py-2">
                                            {section.icon}
                                            <h3 className="text-lg font-trendaSemiBold text-text-primary-light ">{section.title}</h3>

                                        </div>
                                        <div className="grid grid-cols-2 gap-2">
                                            {
                                                section.tabs.map((tab, tabIndex) => (
                                                    <div
                                                        key={tabIndex}
                                                        className="flex gap-4 bg-white px-4 py-2 rounded-lg border-secondary-light cursor-pointer  hover:bg-secondary-light"
                                                        onClick={() => openTab(tab)}
                                                    >
                                                        <div className="flex items-start w-4 pt-1 text-text-primary-light">
                                                            {tab.icon}
                                                        </div>
                                                        <div className="flex flex-col">
                                                            <h4 className="text-lg font-trendaSemiBold text-text-primary-light ">{tab.title}</h4>
                                                            <p className={`${descriptionStyle}`}>{tab.description}</p>
                                                        </div>

                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        )
                    })
                }
            </div>
        },
        {
            title: "Generative Analytics",
            description: "Unlock predictive insights with advanced generative models.",
            layer: <div className="flex flex-col gap-4 pt-4">
                {
                    menu?.map((section: Section | Tabs, elemIndex) => {
                        return (
                            <div key={elemIndex}>
                                {isSection(section) && section.title == "Generative Analytics" &&
                                    <div className="flex flex-col" key={elemIndex}>
                                        <div className="grid grid-cols-2 gap-2">
                                            {
                                                section.tabs.map((tab, tabIndex) => (
                                                    <div
                                                        key={tabIndex}
                                                        className="flex gap-4 bg-white px-4 py-2 rounded-lg border-secondary-light cursor-pointer  hover:bg-secondary-light"
                                                        onClick={() => openTab(tab)}
                                                    >
                                                        <div className="flex items-start w-4 pt-1 text-text-primary-light">
                                                            {tab.icon}
                                                        </div>
                                                        <div className="flex flex-col">
                                                            <h4 className="text-lg font-trendaSemiBold text-text-primary-light ">{tab.title}</h4>
                                                            <p className={`${descriptionStyle}`}>{tab.description}</p>
                                                        </div>

                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        )
                    })
                }
            </div>
        },
        {
            title: "AI Factory",
            description: "Easily train and deploy AI models using our Streamlit-based platform.",
            layer: <div className="flex flex-col gap-4 pt-4">
                {
                    menu?.map((section: Section | Tabs, elemIndex) => {
                        return (
                            <div key={elemIndex}>
                                {isSection(section) && section.title == "AI Factory" &&
                                    <div className="flex flex-col" key={elemIndex}>
                                        <div className="grid grid-cols-2 gap-2">
                                            {
                                                section.tabs.map((tab, tabIndex) => (
                                                    <div
                                                        key={tabIndex}
                                                        className="flex gap-4 bg-white px-4 py-2 rounded-lg border-secondary-light cursor-pointer  hover:bg-secondary-light"
                                                        onClick={() => openTab(tab)}
                                                    >
                                                        <div className="flex items-start w-4 pt-1 text-text-primary-light">
                                                            {tab.icon}
                                                        </div>
                                                        <div className="flex flex-col">
                                                            <h4 className="text-lg font-trendaSemiBold text-text-primary-light ">{tab.title}</h4>
                                                            <p className={`${descriptionStyle}`}>{tab.description}</p>
                                                        </div>

                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        )
                    })
                }
            </div>
        },
        {
            title: "Data Governance",
            description: "Ensure data integrity and compliance with robust governance tools.",
            layer: undefined
        },
    ];

    useEffect(() => {
        topRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [topRef]);

    return (
        <div className={`w-full text-token-text-primary ${!chatAccess && "mt-20"}`} ref={topRef}>
            <div className="text-base py-[18px] px-3 md:px-4 m-auto lg:px-1 xl:px-5">
                <div className="mx-auto flex flex-1 gap-4 text-base md:gap-5 lg:gap-6 md:max-w-3xl lg:max-w-[40rem] xl:max-w-[48rem]">
                    <div className="group/conversation-turn relative flex w-full min-w-0 flex-col">
                        <div className="flex-col gap-1 md:gap-3">
                            <div className="flex flex-grow flex-col max-w-full">
                                <div className="container mx-auto px-4 pb-8">
                                    <div className="mb-4">
                                        <h1 className="text-center text-4xl font-trendaSemiBold text-text-primary-light pb-6">Smart Data Hub</h1>
                                        <p className={`text-center ${descriptionStyle}`}>
                                            Welcome to our platform, where you'll discover convenient direct access to your <span className="font-bold">dashboards</span>.<br />
                                            Moreover, we're thrilled to introduce our latest advancements on our <span className="font-bold">Generative AI apps</span>.
                                        </p>
                                        <p className={`text-center ${descriptionStyle} pt-2 pb-4`}>These innovative tools showcase a range of functionalities, including generative analytics, a secure ChatGPT feature, and Translator capabilities.</p>
                                    </div>

                                    <div className="grid grid-cols-2 gap-4">
                                        {items.map((item, index) => (
                                            <div
                                                key={index}
                                                className="bg-white p-6 rounded-lg shadow-md cursor-pointer hover:shadow-lg hover:bg-secondary-light transition-shadow"
                                                onClick={() => scrollToSection(index)}
                                            >
                                                <h2 className="text-2xl font-trendaSemiBold text-text-primary-light mb-2">{item.title}</h2>
                                                <p className={`${descriptionStyle}`}>{item.description}</p>
                                            </div>
                                        ))}
                                    </div>

                                    {
                                        chatAccess &&
                                        items.map((item, index) => {
                                            if (!item.layer)
                                                return <div key={index}></div>
                                            return (
                                                <div
                                                    key={index}
                                                    ref={(el) => (sectionRefs.current[index] = el)}
                                                    className="mb-12 pt-14"
                                                >
                                                    <div>
                                                        <h3 className="text-2xl font-trendaSemiBold text-text-primary-light ">{item.title}</h3>
                                                        <p className={`${descriptionStyle}`}>{item.description}</p>
                                                    </div>
                                                    {
                                                        item.layer
                                                    }
                                                </div>
                                            )
                                        })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
