import { useState } from "react";

interface SelectOptionsProps<T> {
    defaultValue: string;
    options: T[];
    renderOption: (option: T) => { value: string; label: string };
    selectedValue: string;
    onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    isCalendar?: boolean
}
  
  export function SelectOptions<T>({
    defaultValue,
    options,
    renderOption,
    selectedValue,
    onChange,
  }: SelectOptionsProps<T>) {
    return (
      <div className="select-options">
        <select value={selectedValue}
          onChange={onChange}
          className="my-2 w-full p-2 border border-gray-300 rounded focus:outline-none"
        >
          {defaultValue !== "" && <option value="">{defaultValue}</option>}
          {options.map((option, index) => {
            const { value, label } = renderOption(option);
            return (
              <option key={index} value={value}>
                {label}
              </option>
            );
          })}
        </select>
      </div>
    );
  }

  export function SelectOptionsQuality<T>({
    defaultValue,
    options,
    renderOption,
    selectedValue,
    onChange,
  }: SelectOptionsProps<T>) {
    return (
      <div className="select-options">
        <select value={selectedValue}
          onChange={onChange}
          className="my-2 p-2 text-[#667085] border border-[#D0D5DD] rounded-xl focus:outline-none"
        >
          {defaultValue !== "" && <option value="">{defaultValue}</option>}
          {options.map((option, index) => {
            const { value, label } = renderOption(option);
            return (
              <option key={index} value={value}>
                {label}
              </option>
            );
          })}
        </select>
      </div>
    );
  }

  export function SelectOptionsDataGouv<T>({
    defaultValue,
    options,
    renderOption,
    selectedValue,
    onChange,
    isCalendar,
  }: SelectOptionsProps<T>) {
    
    const [isOpen, setIsOpen] = useState(false);

    const handleOptionClick = (value: string) => {
      const event = {
        target: { value },
      } as React.ChangeEvent<HTMLSelectElement>;

      onChange(event);
      setIsOpen(false);
    };

    const toggleDropdown = () => {
      setIsOpen(!isOpen);
    };
  
    return (
      <div className="select-options relative">
        <div onClick={toggleDropdown} className="w-[130px] bg-transparent py-2 text-sm border border-[#D0D5DD] bg-opacity-30 text-[#344054] rounded-xl focus:outline-none cursor-pointer">
          <div className="flex justify-center items-center gap-2">
            {isCalendar ? 
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.3333 1.66667V5M6.66667 1.66667V5M2.5 8.33333H17.5M4.16667 3.33333H15.8333C16.7538 3.33333 17.5 4.07953 17.5 5V16.6667C17.5 17.5871 16.7538 18.3333 15.8333 18.3333H4.16667C3.24619 18.3333 2.5 17.5871 2.5 16.6667V5C2.5 4.07953 3.24619 3.33333 4.16667 3.33333Z" stroke="#90A7B9" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            : <svg
                width="18"
                height="18"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 10H15M2.5 5H17.5M7.5 15H12.5"
                  stroke="#90A7B9"
                  strokeWidth="1.67"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>}
              <span className="font-bold">{selectedValue || defaultValue || "Select an option"}</span>
              <svg width="14" height="10" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1.5L6 6.5L11 1.5" stroke="#A8ADB5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
          </div>
          {isOpen && (
            <ul className="absolute top-full left-0 w-full bg-white border border-[#D0D5DD] max-h-[300px] overflow-y-scroll no-scrollbar rounded-xl mt-1 shadow-lg z-10">
              {options.map((option, index) => {
                const { value, label } = renderOption(option);
                return (
                  <li
                    key={index}
                    className={`px-3 py-2 flex items-center gap-2 cursor-pointer hover:bg-gray-100 ${
                      value === selectedValue ? "bg-gray-200" : ""
                    }`}
                    onClick={() => handleOptionClick(value)}
                  >
                    <span>{label}</span>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </div>
    );
}

  interface SelectOptionsProps<T> {
    defaultValue: string;
    options: T[];
    renderOption: (option: T) => { value: string; label: string };
    selectedValue: string;
    onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}
  
  export function SelectOptionsNew<T>({
    defaultValue,
    options,
    renderOption,
    selectedValue,
    onChange,
  }: SelectOptionsProps<T>) {
    return (
      <div className="select-options w-full">
        <select
          value={selectedValue}
          onChange={onChange}
          className="my-2 rounded-xl w-full p-2 border border-gray-300 bg-transparent"
        >
          {defaultValue === "-" && <option value="">{defaultValue}</option>}
          {options.map((option, index) => {
            const { value, label } = renderOption(option);
            return (
              <option key={index} value={value}>
                {label}
              </option>
            );
          })}
        </select>
      </div>
    );
  }