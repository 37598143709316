import { createContext, useContext, useEffect, useState } from "react";
import { ChatGPTGestureHook, ChatGPTInputHook, ChatGPTModelHook, SecuredGPTConfig, useChatGPTGesture, useChatGPTInput, useChatGPTModel, useChatGPTConfig } from "../hooks";

export type ModalStatus = 'CLOSE' | 'MODEL' | 'PARAMS' | 'DOCUMENT_OPT' | 'TRANSLATOR' | 'MULTI_DOCS' | 'SENTIMENT_ANALYSIS' | 'MULTIMODAL';

interface CreditContextType {
  gptConfig: SecuredGPTConfig;
  gptModel: ChatGPTModelHook;
  gptInput: ChatGPTInputHook;
  chatGPTAsk: {
    loadingGPT: boolean;
    setLoadingGPT: React.Dispatch<React.SetStateAction<boolean>>;
    errorRequestGPT: boolean;
    setErrorRequestChpt: React.Dispatch<React.SetStateAction<boolean>>;
  };
  gptGesture: ChatGPTGestureHook;
  modalStatus: ModalStatus;
  setModalStatus: (status: ModalStatus) => void;
  homeStatus: boolean;
  setHomeStatus: React.Dispatch<React.SetStateAction<boolean>>;
  chatStatus: "ASSISTANT_OPEN" | "ASSISTANT_CLOSE"  | "CLASSIC"
  setChatStatus: React.Dispatch<React.SetStateAction<"ASSISTANT_OPEN" | "ASSISTANT_CLOSE" | "CLASSIC">>
}

const ChatGPTContext = createContext<CreditContextType | undefined>(undefined);


export const ChatGPTProvider = ({ children }: React.PropsWithChildren) => {
  const gptConfig = useChatGPTConfig();
  const gptGesture = useChatGPTGesture()
  const gptInput = useChatGPTInput();
  const gptModel = useChatGPTModel(gptInput, gptConfig)

  const [modalStatus, setModalStatus] = useState<ModalStatus>('CLOSE');
  const [homeStatus, setHomeStatus] = useState<boolean>(true);
  const [chatStatus, setChatStatus] = useState<"ASSISTANT_OPEN" | "ASSISTANT_CLOSE" | "CLASSIC">("CLASSIC");

  const [loadingGPT, setLoadingGPT] = useState<boolean>(false); //loader
  const [errorRequestGPT, setErrorRequestChpt] = useState<boolean>(false)

  const chatGPTAsk = {
    loadingGPT,
    setLoadingGPT,
    errorRequestGPT,
    setErrorRequestChpt
  }

  return (
    <ChatGPTContext.Provider value={{
      setChatStatus,
      chatStatus,
      modalStatus,
      setModalStatus,
      gptConfig,
      gptModel,
      gptInput,
      chatGPTAsk,
      gptGesture,
      homeStatus,
      setHomeStatus
    }}>
      {children}
    </ChatGPTContext.Provider>
  );
};

export const useChatGPT = () => {
  const context = useContext(ChatGPTContext);
  if (!context) {
    throw new Error('useChatGPT must be used within a ChatGPTProvider');
  }
  return context;
};