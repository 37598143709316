import React, { useState, useEffect } from 'react';
import {
  ReactFlow,
  ReactFlowProvider,
  Background,
  Node,
  Edge,
  MarkerType,
  Position
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import { useDataGouv } from './providers/DataGouvProvider';

type KPIData = { label: string; explanation?: string };
export type KPIType = Node<KPIData>;
type KPIEdgeType = Edge<{ label: string }>;

interface LineageProps {
  selectedKpi: string | null;
  setSelectedNode: React.Dispatch<React.SetStateAction<KPIType | null>>
  scrollContainerRef: React.RefObject<HTMLDivElement>;

}

export const Lineage: React.FC<LineageProps> = ({ selectedKpi, setSelectedNode, scrollContainerRef }) => {
  const { nodes: backendNodes, edges: backendEdges } = useDataGouv();
  const [nodes, setNodes] = useState<KPIType[]>([]);
  const [originalEdges, setOriginalEdges] = useState<KPIEdgeType[]>([]);
  const [displayedEdges, setDisplayedEdges] = useState<KPIEdgeType[]>([]);
  const [reactFlowInstance, setReactFlowInstance] = useState<any>(null);
  const [adjacencyList, setAdjacencyList] = useState<Map<string, string[]>>(new Map());

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');

  

  useEffect(() => {
    if (!backendNodes) return;

    const createNodes = () => {
      const newNodes: KPIType[] = [];
      const globalShift = -300;
      let xPosition = 0;

      const visualMappings: Record<
        string,
        { displayName: string; backgroundColor: string; textColor: string }
      > = {
        PowerBI: { displayName: 'Power BI Tables', backgroundColor: '#F2C80F', textColor: 'black' },
        'PowerBI Measures': {
          displayName: 'Power BI KPIs',
          backgroundColor: '#2F8D79',
          textColor: 'white',
        },
        GoogleBigQuery: { displayName: 'GCP Tables', backgroundColor: '#4081EC', textColor: 'black' },
      };

      Object.entries(backendNodes).forEach(([columnName, tables]) => {
        let yPosition = 100 + globalShift;

        const columnWidth = 300;

        const visualMapping = visualMappings[columnName] || {
          displayName: columnName.replace(/_/g, ' '),
          backgroundColor: '#585858',
          textColor: 'white',
        };
        const { displayName, backgroundColor, textColor } = visualMapping;

        const headerNodeId = `header|${columnName}`;
        newNodes.push({
          id: headerNodeId,
          position: { x: xPosition + columnWidth / 2 - 100, y: 0 + globalShift },
          data: { label: displayName },
          type: 'default',
          style: {
            fontWeight: 'bold',
            fontSize: '23px',
            textAlign: 'center',
            backgroundColor,
            color: textColor,
            padding: '10px',
            borderRadius: '30px',
            width: 'auto',
            minWidth: '300px',
          },
        });

        Object.entries(tables).forEach(([tableName, kpis]) => {
          const cleanedTableName = tableName.replace(/_/g, ' ');

          const tableNodeId = `${columnName}|${tableName}`;
          newNodes.push({
            id: tableNodeId,
            position: { x: xPosition + columnWidth / 2 - 100, y: yPosition },
            data: { label: cleanedTableName },
            type: 'default',
            style: {
              fontWeight: 'bold',
              fontSize: '20px',
              textAlign: 'center',
              backgroundColor: '#e6f7ff',
              padding: '8px',
              borderRadius: '30px',
              border: '1px solid #3498db',
              width: 'auto',
              minWidth: '300px',
            },
          });

          kpis.forEach((kpiInfo: any, kpiIndex: number) => {
            const cleanedKpiName = kpiInfo.kpi.replace(/_/g, ' ');
            const kpiNodeId = `${columnName}|${tableName}|${kpiInfo.kpi}`;

            newNodes.push({
              id: kpiNodeId,
              position: {
                x: xPosition + columnWidth / 2 - 75,
                y: yPosition + (kpiIndex + 1) * 60,
              },
              data: {
                label: cleanedKpiName,
                explanation: kpiInfo.explanation,
              },
              type: 'default',
              style: {
                fontSize: '20px',
                textAlign: 'center',
                backgroundColor: '#ffffff',
                padding: '6px',
                borderRadius: '4px',
                border: '1px solid #d9d9d9',
                width: 'auto',
                minWidth: '250px',
              },
              sourcePosition: Position.Right,
              targetPosition: Position.Left,
            });
          });

          yPosition += Math.max(kpis.length * 60 + 60, 150);
        });

        xPosition += columnWidth + 50;
      });

      setNodes(newNodes);
    };

    createNodes();
  }, [backendNodes]);

  useEffect(() => {
    if (!backendEdges) return;

    const allEdges: KPIEdgeType[] = [];

    Object.entries(backendEdges).forEach(([columnName, tables]) => {
      Object.entries(tables).forEach(([tableName, edgeList]) => {
        edgeList.forEach((edge) => {
          const sourceNodeId = `${edge.source.column}|${edge.source.table}|${edge.source.kpi}`;
          const targetNodeId = `${edge.target.column}|${edge.target.table}|${edge.target.kpi}`;

          allEdges.push({
            id: `edge-${sourceNodeId}-${targetNodeId}`,
            source: sourceNodeId,
            target: targetNodeId,
            animated: true,
            style: { stroke: 'black', strokeWidth: 2, zIndex: 50},
            markerEnd: { type: MarkerType.ArrowClosed, color: 'black' },
            data: { label: `${edge.source.kpi} → ${edge.target.kpi}` },
            type: 'straight'
          });
        });
      });
    });

    setOriginalEdges(allEdges);

    const adjList = new Map<string, string[]>();

    allEdges.forEach((edge) => {
      if (!adjList.has(edge.source)) {
        adjList.set(edge.source, []);
      }
      adjList.get(edge.source)!.push(edge.target);
    });

    setAdjacencyList(adjList);
  }, [backendEdges]);

  useEffect(() => {
    if (reactFlowInstance) {
      recenterGraph()
    }
  }, [backendEdges,backendNodes, reactFlowInstance]);

  const recenterGraph = () => {
    if (reactFlowInstance) {
      reactFlowInstance.fitView({ padding: 0.1 });
    }
  };

  const traverseGraph = (startNodeId: string): { nodes: Set<string>; edges: Set<string> } => {
    const visitedNodes = new Set<string>();
    const visitedEdges = new Set<string>();
    const stack = [startNodeId];

    while (stack.length > 0) {
      const currentNodeId = stack.pop()!;
      if (visitedNodes.has(currentNodeId)) continue;
      visitedNodes.add(currentNodeId);

      const neighbors = adjacencyList.get(currentNodeId) || [];
      neighbors.forEach((neighbor) => {
        const edgeId = `edge-${currentNodeId}-${neighbor}`;
        visitedEdges.add(edgeId);
        if (!visitedNodes.has(neighbor)) {
          stack.push(neighbor);
        }
      });
    }

    return { nodes: visitedNodes, edges: visitedEdges };
  };

  const handleNodeClick = (_: React.MouseEvent, clickedNode: KPIType) => {
    const { nodes: visitedNodeIds, edges: visitedEdgeIds } = traverseGraph(clickedNode.id);
  
    const edgesToDisplay = originalEdges.filter((e) => visitedEdgeIds.has(e.id));
    setDisplayedEdges([]);
    setTimeout(() => setDisplayedEdges(edgesToDisplay), 0);
  
    const explanation = clickedNode.data.explanation || 'No explanation available.';
    setSelectedNode(clickedNode);
    setIsModalOpen(true);
  };

  const handlePaneClick = () => {
    setDisplayedEdges([]);
    setIsModalOpen(false);
  };

  const getBoundingBox = (nodes: Node[]): [[number, number], [number, number]] => {
    let minX = Infinity, maxX = -Infinity, minY = Infinity, maxY = -Infinity;
  
    nodes.forEach((node) => {
      const { x, y } = node.position;
      minX = Math.min(minX, x);
      maxX = Math.max(maxX, x);
      minY = Math.min(minY, y);
      maxY = Math.max(maxY, y);
    });
  
    return [
      [minX - 1000, minY - 1000],
      [maxX + 1000, maxY + 1000],
    ];
  };  

  const translateExtent = getBoundingBox(nodes);


  return (
    <div className="w-full h-full relative min-h-[800px]">
      <button
        onClick={recenterGraph}
        className="bg-transparent py-2 px-3 text-sm border border-[#D0D5DD] bg-opacity-30 text-[#344054] rounded-xl focus:outline-none cursor-pointer"
      >
        Recenter Graph
      </button>
      <ReactFlowProvider>
          <ReactFlow
            nodes={nodes}
            edges={displayedEdges}
            onNodeClick={handleNodeClick}
            onPaneClick={handlePaneClick}
            translateExtent={translateExtent}
            fitView
            onInit={setReactFlowInstance}
            nodesDraggable={false} 
            elementsSelectable={false}
            panOnScroll={true}
            panOnDrag={true} 
            zoomOnScroll={false} 
            zoomOnPinch={false} 
            preventScrolling={true} 
          >
          <Background />
        </ReactFlow>
      </ReactFlowProvider>
    </div>
  );
};