import React, { useEffect, useState } from 'react';
import { Worker } from '@react-pdf-viewer/core';
import { Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { pageNavigationPlugin, RenderCurrentPageLabelProps } from '@react-pdf-viewer/page-navigation';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../../providers';
import { SVGIcon } from '../../assets';

const workerUrl = `${window.location.origin}/pdf.worker.min.js`;

export const PDFViewer = () => {
    const { isAuthenticated, token } = useAuth();
    const [searchParams] = useSearchParams();
    const uri = searchParams.get("uri") || "";
    const page = parseInt(searchParams.get("page") || "0", 10);
    const [pdfUrl, setPdfUrl] = useState('');
    const [error, setError] = useState(false);

    const [currentPage, setCurrentPage] = useState(page - 1);

    const pageNavigationPluginInstance = pageNavigationPlugin();
    const { CurrentPageLabel } = pageNavigationPluginInstance;

    const fetchPDF = async () => {
        try {
            const response = await fetch(`/api/pdf/document?uri=${encodeURIComponent(uri)}`, {
                method: "GET",
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                setError(true)
                return
            }

            const blob = await response.blob();
            const url = URL.createObjectURL(blob);
            console.log(url)
            setPdfUrl(url);
        } catch (error) {
            console.error('Error fetching PDF:', error);
        }
    };

    useEffect(() => {
        if (isAuthenticated && !pdfUrl && token) {
            fetchPDF()
        }
    }, [isAuthenticated, pdfUrl, token, fetchPDF])

    useEffect(() => {
        setCurrentPage(page - 1);
    }, [page, pdfUrl]);

    const getFileNameFromUrl = (url: string) => {
        const urlParts = url.split('/');
        return urlParts[urlParts.length - 1] || 'document.pdf'; // Par défaut 'document.pdf' si le nom n'est pas trouvé
    };

    if (!isAuthenticated || error) {
        return (<div className="flex flex-col items-center justify-center h-screen bg-gray-100">
            <h1 className="text-4xl font-bold text-red-600">403</h1>
            <p className="text-xl text-gray-800">Unauthorized</p>
            <p className="text-gray-600 mt-4">
                You do not have permission to access this page.
            </p>
        </div>)
    } else {
        return (
            <div className='flex flex-col'>
                {
                    pdfUrl &&
                    <>
                        <div className="sticky top-0 z-10 flex w-full p-2 justify-between bg-white border-b border-gray-300">
                            <CurrentPageLabel>
                                {(props: RenderCurrentPageLabelProps) => (
                                    <span className='px-2 py-1 flex text-text-secondary-light font-trendaSemiBold text-sm'>
                                        Page {props.currentPage + 1} of {props.numberOfPages}
                                    </span>
                                )}
                            </CurrentPageLabel>
                            <div className="flex">
                                <SVGIcon size={"96px"} icon="BIG_VINCI_ICON" />
                            </div>
                            <button onClick={() => {
                                const fileName = getFileNameFromUrl(uri)
                                const link = document.createElement('a');
                                link.href = pdfUrl;
                                link.download = fileName;
                                link.click();
                            }}
                                className="cursor-pointer flex text-text-secondary-light font-trendaSemiBold px-2 py-1 border border-[#CFCFCF80] rounded-lg text-sm justify-center items-center hover:text-white hover:bg-text-secondary-light hover:border-text-secondary-light">Download</button>

                        </div>
                        <div className="h-screen border border-gray-300">
                            <Worker workerUrl={workerUrl}>
                                <Viewer
                                    fileUrl={pdfUrl}
                                    plugins={[pageNavigationPluginInstance]}
                                    defaultScale={SpecialZoomLevel.PageFit}
                                    onPageChange={(e) => setCurrentPage(e.currentPage)}
                                    initialPage={currentPage}
                                />
                            </Worker>
                        </div>
                    </>
                }
            </div>
        );
    }
};

export default PDFViewer;