import React, { useEffect, useState } from 'react';
import { useActiveTabs, useAuth } from '../../providers';
import { useStreamSession } from '../../hooks/useStreamSession';

export const StreamlitApp: React.FC = () => {
  const [streamlitUrl, setStreamlitUrl] = useState<string | null>(null);
  const [inputUrl, setInputUrl] = useState<string>('');
  const [sessionId, setSessionId] = useState<string | null>(null);
  const { token } = useAuth();
  const { closeTab } = useActiveTabs();

  const sessionState = useStreamSession(sessionId, token);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputUrl(event.target.value);
  };

  const handleButtonClick = async () => {
    try {
      const response = await fetch(`/api/streamlit/local_url?url=${inputUrl}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setStreamlitUrl(data.url);
    } catch (error) {
      console.error('Error fetching the Streamlit URL:', error);
    }
  };

  // Extract session ID from URL
  useEffect(() => {
    if (streamlitUrl) {
      const urlObj = new URL(streamlitUrl);
      const uuid = urlObj.searchParams.get("uuid");
      setSessionId(uuid);
    }
  }, [streamlitUrl]);

  // Handle session expiration
  useEffect(() => {
    if (sessionState.status === 'expired' || sessionState.status === 'not_found') {
      const cleanup = async () => {
        if (sessionId) {
          try {
            await fetch(`/api/streamlit/delete-session/${sessionId}`, {
              method: 'DELETE',
              headers: {
                'Authorization': `Bearer ${token}`
              }
            });
          } catch (error) {
            console.error('Error deleting session:', error);
          }
        }
        setSessionId(null);
        closeTab(1000);
      };

      cleanup();
    }
  }, [sessionState.status, sessionId, token, closeTab]);

  return (
    <div className="p-6 max-w-4xl mx-auto">
      <h1 className="text-2xl font-bold mb-4">Streamlit</h1>
      <div className="mb-4">
        <input
          type="text"
          value={inputUrl}
          onChange={handleInputChange}
          placeholder="Enter URL"
          className="p-2 border border-gray-300 rounded-md w-full"
        />
      </div>
      <div className="mb-4">
        <button
          onClick={handleButtonClick}
          className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
        >
          Load Streamlit App
        </button>
      </div>
      {streamlitUrl ? (
        <iframe
          src={streamlitUrl}
          width="800"
          height="600"
          title="Streamlit App"
          className="border border-gray-300 rounded-md"
        />
      ) : (
        <p className="text-gray-600">
          Please enter a URL and click the button to load the Streamlit app.
        </p>
      )}
    </div>
  );
};
