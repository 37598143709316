import { useEffect, useState } from "react";

interface SessionState {
    startTime: string | null;
    elapsedTime: number | null;
    status: 'active' | 'expired' | 'not_found' | 'error';
    error: string | null;
}

export const useStreamSession = (sessionId: string | null, token: string | null) => {
    const [sessionState, setSessionState] = useState<SessionState>({
        startTime: new Date().toISOString(),
        elapsedTime: 0,
        status: 'active',
        error: null
    });

    useEffect(() => {
        if (!sessionId || !token) return;

        let abortController = new AbortController();
        let active = true;

        const monitorSession = async () => {
            try {
                const response = await fetch(`/api/streamlit/stream-session/${sessionId}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    signal: abortController.signal
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const reader = response.body?.getReader();
                if (!reader) throw new Error('No reader available');

                const decoder = new TextDecoder();
                let buffer = '';

                while (active) {
                    const { done, value } = await reader.read();
                    if (done) break;

                    buffer += decoder.decode(value, { stream: true });
                    const lines = buffer.split('\n\n');
                    buffer = lines.pop() || '';
                    for (const line of lines) {
                        if (line.startsWith('data: ')) {
                            const data = JSON.parse(line.slice(6));

                            if (!active) break;

                            setSessionState({
                                startTime: data.start_time,
                                elapsedTime: data.elapsed_time,
                                status: data.status,
                                error: null
                            });

                            if (data.status === 'expired' || data.status === 'not_found') {
                                return;
                            }
                        }
                    }
                }
            } catch (error) {
                setSessionState(prev => ({
                    ...prev,
                    status: 'error',
                }));

                if (active) {
                    setTimeout(() => {
                        if (active) monitorSession();
                    }, 5000);
                }
            }
        };

        monitorSession();

        return () => {
            active = false;
            abortController.abort();
        };
    }, [sessionId, token]);

    return sessionState;
};