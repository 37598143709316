import { createContext, useContext, useEffect, useState } from "react";
import { useDataQuality } from "../hooks/useDataQuality";
import { useAuth } from "../../../providers";
import { useDataIngestion } from "../hooks/useDataIngestion";
import { FileStatusProps } from "../components/FileStatus";
import { IngestionData } from "../hooks/useDataIngestion";
import { ReferentielData, useReferentiel } from "../hooks/useReferentiel";
import { FileStatusRefProps } from "../components/FileStatusReferentiel";
import { EdgesData, LineageKpisData, NodesData, useDataLineage } from "../hooks/useDataLineage";

interface FileData {
    file_type: string;
    is_version: number;
    num_version: number;
}

interface AssetData {
    [key: string]: FileData[];
}

interface FakeDataProps {
    fileType: string;
    status: FileStatusProps['status']
}

interface DataGouvContextType {
    buSelected: string | null;
    setBuSelected: React.Dispatch<React.SetStateAction<string | null>>;
    dateSelected: string | null;
    setDateSelected: React.Dispatch<React.SetStateAction<string | null>>;
    setMonthYearSelected: React.Dispatch<React.SetStateAction<string | null>>;
    monthYearSelected: string | null;
    dataQuality: any;
    buOptions: string[];
    dateOptions: string[];
    getQualityDetailForBu: (asset: string, use_case: string, year: number) => Promise<any>;
    lateFilesNumber: number;
    ingestionData: IngestionData | null;
    uploadFileFromDropzone: (file_asset: string, file_type: string, file: File,version?: string) => Promise<any>;
    uploadFileFromDropzoneRef: (file_type: string, file: File, file_url: string) => Promise<any>;
    uploadFileFromDropzoneRefIngestion: (file_type:string, file: File, buSelected: string, month: string, year:string) => Promise<any>
    confirmFileAfterKpis: (file_id: string, file_type: string, version?: string) => Promise<any>
    rejectKPIs: (file_asset: string, file_type: string,version?: string) => void;
    setUseCase: React.Dispatch<React.SetStateAction<string | null>>;
    app: string;
    setApp: React.Dispatch<React.SetStateAction<string>>;
    loaderIngestionData: boolean;
    dataReferentiel: ReferentielData | null
    dataLineage: LineageKpisData[] | null
    nodes: NodesData | null
    edges: EdgesData | null
    getTables: (filePath: string) => Promise<void | null>;
    getEdges: (filePath: string) => Promise<void | null>;
    loaderOverview: boolean
}

const DataGouvContext = createContext<DataGouvContextType | undefined>(undefined);

export const DataGouvProvider = ({children}: React.PropsWithChildren) => {
    const [buSelected, setBuSelected] = useState<string | null>(null);
    const [dateSelected, setDateSelected] = useState<string | null>(null);
    const [monthYearSelected, setMonthYearSelected] = useState<string | null>(null)
    const [buOptions, setBuOptions] = useState<string[]>([]);
    const [dateOptions, setDateOptions] = useState<string[]>([]);
    const [lateFilesNumber, setLateFilesNumber] = useState<number>(0);
    const { token } = useAuth();
    const { dataQuality, getQualityDetailForBu, loaderOverview } = useDataQuality(token);
    const [useCase, setUseCase] = useState<string | null>(null)

    const [app,setApp] = useState('Quality')

    const { uploadFile, getIngestionData, ingestionData, setIngestionData, confirmFile, loaderIngestionData} = useDataIngestion(token);
    const {getReferentielData, dataReferentiel, uploadFileRef, setDataReferentiel, uploadFileRefIngestion} = useReferentiel(token)
    const {dataLineage, getLineageKpis, getTables, nodes, getEdges, edges} = useDataLineage(token)
    
    useEffect(() => {
        if (buSelected && monthYearSelected && useCase) {
            getIngestionData(useCase.toLocaleUpperCase(),buSelected, monthYearSelected!);
        }
    }, [buSelected,monthYearSelected, useCase]);

    useEffect(()=>{
        if(buSelected){
            getReferentielData(useCase?.toLocaleUpperCase(),buSelected)
        }
    },[buSelected])

    useEffect(()=>{
        if(useCase){
            getLineageKpis('Traffic')
        }
    },[useCase])

    const uploadFileFromDropzoneRef = async (file_type: string, file: File, file_url: string) => {
        if (dataReferentiel) {
            console.log(dataReferentiel)
            try {
                setDataReferentiel((prevData) => {
                    if (!prevData || !buSelected || !prevData[buSelected]) return prevData;
    
                    return {
                        ...prevData,
                        [buSelected as string]: prevData[buSelected as string].map((row) => {
                            const fileTypeMatch = row.file_type === file_type; 
    
                            if (fileTypeMatch) {
                                const statusKeys: (keyof FileStatusRefProps['status'])[] = ['loaded', 'checkfile', 'processed'];
                                const updatedStatus = { ...row.status };
                                statusKeys.forEach(key => {
                                    if (updatedStatus[key] === 'error' || updatedStatus[key] === 'finished' || updatedStatus[key] === 'running') {
                                        updatedStatus[key] = 'pending';
                                    }
                                });
                    
                                return {
                                    ...row,
                                    status: { ...updatedStatus, loaded: 'running' },
                                    error: undefined,
                                };
                            }
                            return row;
                        }),
                    };
                });
    
                const data = await uploadFileRef(file_type, file, buSelected!, 'FINANCE');
    
                setDataReferentiel((prevData) => {
                    if (!prevData || !buSelected || !prevData[buSelected]) return prevData;
    
                    return {
                        ...prevData,
                        [buSelected as string]: prevData[buSelected as string].map((row) => {
                            const fileTypeMatch = row.file_type === file_type;
                            console.log(row);
                            if (fileTypeMatch) {
                                return {
                                    ...row,
                                    status: {
                                        loaded: data.status.loaded,
                                        checkfile: data.status.checkfile,
                                        processed: data.status.processed,
                                    },
                                    final_filename: data.final_filename,
                                    error: data.error ? data.error : undefined,
                                };
                            }
                            return row;
                        }),
                    };
                });
            } catch (error) {
                console.error('Error during file upload process:', error);
                setDataReferentiel((prevData) => {
                    if (!prevData || !buSelected || !prevData[buSelected]) return prevData;
    
                    return {
                        ...prevData,
                        [buSelected as string]: prevData[buSelected as string].map((row) => {
                            const fileTypeMatch = row.file_type === file_type;
    
                            if (fileTypeMatch) {
                                return {
                                    ...row,
                                    status: { ...row.status, loaded: 'error' },
                                    error: {
                                        title: 'Error',
                                        description: ''
                                    },
                                };
                            }
                            return row;
                        }),
                    };
                });
            }
        }
    };

    const uploadFileFromDropzoneRefIngestion = async (file_type:string, file: File, buSelected: string, month: string, year:string) => {
        if (dataReferentiel) {
            console.log(dataReferentiel)
            try {
                setDataReferentiel((prevData) => {
                    if (!prevData || !buSelected || !prevData[buSelected]) return prevData;
    
                    return {
                        ...prevData,
                        [buSelected as string]: prevData[buSelected as string].map((row) => {
                            const fileTypeMatch = row.file_type === file_type; 
    
                            if (fileTypeMatch) {
                                const statusKeys: (keyof FileStatusRefProps['status'])[] = ['loaded', 'checkfile', 'processed'];
                                const updatedStatus = { ...row.status };
                                statusKeys.forEach(key => {
                                    if (updatedStatus[key] === 'error' || updatedStatus[key] === 'finished' || updatedStatus[key] === 'running') {
                                        updatedStatus[key] = 'pending';
                                    }
                                });
                    
                                return {
                                    ...row,
                                    status: { ...updatedStatus, loaded: 'running' },
                                    error: undefined,
                                };
                            }
                            return row;
                        }),
                    };
                });
    
                const data = await uploadFileRefIngestion(file_type ,file, buSelected!, '2024', '11');
    
                setDataReferentiel((prevData) => {
                    if (!prevData || !buSelected || !prevData[buSelected]) return prevData;
    
                    return {
                        ...prevData,
                        [buSelected as string]: prevData[buSelected as string].map((row) => {
                            const fileTypeMatch = row.file_type === file_type;
                            console.log(row);
                            if (fileTypeMatch) {
                                return {
                                    ...row,
                                    status: {
                                        loaded: data.status.loaded,
                                        checkfile: data.status.checkfile,
                                        processed: data.status.processed,
                                    },
                                    final_filename: data.final_filename,
                                    error: data.error ? data.error : undefined,
                                    file_url: data.file_url
                                };
                            }
                            return row;
                        }),
                    };
                });
            } catch (error) {
                console.error('Error during file upload process:', error);
                setDataReferentiel((prevData) => {
                    if (!prevData || !buSelected || !prevData[buSelected]) return prevData;
    
                    return {
                        ...prevData,
                        [buSelected as string]: prevData[buSelected as string].map((row) => {
                            const fileTypeMatch = row.file_type === file_type;
    
                            if (fileTypeMatch) {
                                return {
                                    ...row,
                                    status: { ...row.status, loaded: 'error' },
                                    error: {
                                        title: 'Error',
                                        description: ''
                                    },
                                };
                            }
                            return row;
                        }),
                    };
                });
            }
        }
    };


    const rejectKPIs = (file_asset: string, file_type: string, version?: string) => {
        const [month, year] = monthYearSelected ? monthYearSelected.split('/') : [undefined, undefined];
        setIngestionData(prevData => {
            if (!prevData) return null;
    
            return {
                ...prevData,
                [buSelected as string]: prevData[buSelected as string].map(row => {
                    const fileTypeMatch = row.file_type === file_type;
                    const versionMatch = !row.version || row.version === version;
    
                    if (fileTypeMatch && versionMatch) {
                        return {
                            ...row,
                            status: {
                                loaded: 'pending',
                                checkfile: 'pending',
                                checkKpis: 'pending',
                                processed: 'pending'
                            },
                            error: undefined,
                            kpis: undefined,
                        };
                    }
                    return row;
                })
            };
        });
    };

    const uploadFileFromDropzone = async (file_asset: string, file_type: string, file: File, version?: string) => {
        if (ingestionData) {
            setIngestionData(prevData => {
                if (!prevData) return null;
    
                return {
                    ...prevData,
                    [buSelected as string]: prevData[buSelected as string].map(row => {
                        const fileTypeMatch = row.file_type === file_type;
                        const versionMatch = !row.version || row.version === version;
                        
                        if (fileTypeMatch && versionMatch) {
                            const statusKeys: (keyof FileStatusProps['status'])[] = ['loaded', 'checkfile', 'checkKpis', 'processed'];
                            const updatedStatus = { ...row.status };
                            statusKeys.forEach(key => {
                                if (updatedStatus[key] === 'error' || updatedStatus[key] === 'finished' || updatedStatus[key] === 'running') {
                                    updatedStatus[key] = 'pending';
                                }
                            });
                            console.log(row)
                            return {
                                ...row,
                                status: { ...updatedStatus, loaded: 'running' },
                                error: undefined,
                            };
                        }
                        return row;
                    })
                };
            });
        }
    
        try {

            const [month, year] = monthYearSelected ? monthYearSelected.split('/') : [undefined, undefined];
            const data = await uploadFile(file_asset, file_type, file, version, month, year);
            const filteredKpis = Array.isArray(data.kpis) ? data.kpis.filter((item:any) => typeof item === 'object') : data.kpis;
            const fileId = data.file_id || null
            
            setIngestionData(prevData => {
                if (!prevData) return null;
    
                return {
                    ...prevData,
                    [buSelected as string]: prevData[buSelected as string].map(row => {
                        const fileTypeMatch = row.file_type === file_type;
                        const versionMatch = !row.version || row.version === version;
                        console.log(row)
                        if (fileTypeMatch && versionMatch) {
                            return {
                                ...row,
                                status: {
                                    loaded: data.status.loaded,
                                    checkfile: data.status.checkfile,
                                    checkKpis: data.status.checkKpis,
                                    processed: data.status.processed,
                                },
                                error: data.error ? data.error : undefined,
                                file_id: fileId,
                                kpis: filteredKpis,
                                
                            };
                        }
                        return row;
                    })
                };
            });
        } catch (error) {
            console.error("File upload failed:", error);
            setIngestionData(prevData => {
                if (!prevData) return null;
    
                return {
                    ...prevData,
                    [buSelected as string]: prevData[buSelected as string].map(row => {
                        const fileTypeMatch = row.file_type === file_type;
                        const versionMatch = !row.version || row.version === version;
    
                        if (fileTypeMatch && versionMatch) {
                            return { 
                                ...row, 
                                status: { 
                                    ...row.status, 
                                    loaded: 'error' 
                                } 
                            };
                        }
                        return row;
                    })
                };
            });
        }
    };    


    const confirmFileAfterKpis = async (file_id: string, file_type: string, version?: string) =>{
        try {
            setIngestionData(prevData => {
                if (!prevData) return null;
    
                return {
                    ...prevData,
                    [buSelected as string]: prevData[buSelected as string].map(row => {
                        const fileTypeMatch = row.file_type === file_type;
                        const versionMatch = !row.version || row.version === version;
                        
                        if (fileTypeMatch && versionMatch) {
                            const updatedStatus = { ...row.status };
                            console.log(row)
                            return {
                                ...row,
                                status: { ...updatedStatus, checkKpis: 'finished', processed: 'running' },
                                error: undefined,
                            };
                        }
                        return row;
                    })
                };
            });
            const data = await confirmFile(file_id)
            if(!data) return;
            setIngestionData(prevData => {
                if (!prevData) return null;
    
                return {
                    ...prevData,
                    [buSelected as string]: prevData[buSelected as string].map(row => {
                        const fileTypeMatch = row.file_type === file_type;
                        const versionMatch = !row.version || row.version === version;
                        console.log(row)
                        if (fileTypeMatch && versionMatch) {
                            return {
                                ...row,
                                status: {
                                    loaded: data.status.loaded,
                                    checkfile: data.status.checkfile,
                                    checkKpis: data.status.checkKpis,
                                    processed: data.status.processed,
                                },
                                error: data.error ? data.error : undefined,
                            };
                        }
                        return row;
                    })
                };
            });
        }
        catch(error){
            console.log(error)
            return null
        }
    }

    useEffect(() => {
        if (dataQuality?.fields?.assets) {
            const dates = Object.keys(dataQuality.fields.assets);
            setDateOptions(dates);

            if (dateSelected && dataQuality.fields.assets[dateSelected]) {
                setBuOptions(dataQuality.fields.assets[dateSelected]);
            }
        }
    }, [dataQuality, dateSelected]);

    // useEffect(() => {
    //     if (dataQuality?.data) {
    //         let totalLateFiles = 0;

    //         Object.keys(dataQuality.data).forEach(year => {
    //             const assets = dataQuality.data[year];
    //             Object.keys(assets).forEach(asset => {
    //                 const assetData = assets[asset];
    //                 Object.keys(assetData).forEach(useCase => {
    //                     const useCaseData = assetData[useCase];
    //                     const lateFiles = useCaseData?.late ?? 0;

    //                     totalLateFiles += lateFiles;
    //                 });
    //             });
    //         });
    //         setLateFilesNumber(totalLateFiles);
    //     }
    // }, [dataQuality]);

    useEffect(() => {
        if (dateOptions.length > 0 && !dateSelected) {
            setDateSelected(dateOptions[0]);
        }
    }, [dateOptions]);

    useEffect(() => {
        if (buOptions.length > 0 && !buSelected) {
            setBuSelected(buOptions[0]);
        }
    }, [buOptions]);

    return (
        <DataGouvContext.Provider value={{
            buSelected,
            setBuSelected,
            dateSelected,
            setDateSelected,
            dataQuality,
            buOptions,
            dateOptions,
            getQualityDetailForBu,
            lateFilesNumber,
            ingestionData,
            uploadFileFromDropzone,
            setUseCase,
            setMonthYearSelected,
            monthYearSelected,
            rejectKPIs,
            app,
            setApp,
            confirmFileAfterKpis,
            loaderIngestionData,
            dataReferentiel,
            uploadFileFromDropzoneRef,
            uploadFileFromDropzoneRefIngestion,
            dataLineage,
            nodes,
            edges,
            getTables,
            getEdges,
            loaderOverview
        }}>
            {children}
        </DataGouvContext.Provider>
    );
};

export const useDataGouv = () => {
    const context = useContext(DataGouvContext);
    if (!context) {
        throw new Error('useDataGouv must be used within a DataGouvProvider');
    }
    return context;
};