import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ActiveTabsProvider, AuthProvider, ThemeProvider } from "./providers";
import { PDFViewer } from "./pages/PDFViewer";
import { Download } from "./pages/Download";

const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<App />} />
    <Route path="/pdf" element={<PDFViewer />} />
    <Route path="/download" element={<Download />} />
  </Routes>
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter future={{ v7_relativeSplatPath: true, v7_startTransition: true }}>
    <AuthProvider>
      <ThemeProvider>
        <ActiveTabsProvider>
          <AppRoutes />
        </ActiveTabsProvider>
      </ThemeProvider>
    </AuthProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
