import { useState } from "react";
import { SVGIcon } from "../../../../assets";
import { LoaderCircle } from "../../../../components";
import { useMultiDocs } from "../../providers/MultiDocsProvider";
import { useMultiModal } from "../../providers/MultiModalProvider";
import { useChatGPT } from "../../providers";

export const AnalyseLinks = () => {
    const [link, setLink] = useState<string>('');
    const { setModalStatus } = useChatGPT()
    const { config } = useMultiModal()

    return (
        <div className="flex flex-col w-full">
            <div className="flex flex-col w-full bg-white p-2 border border-[#CFCFCF] justify-center rounded-xl">
                <input
                    type="text"
                    placeholder="URL to scrape"
                    className="placeholder-gray-custom p-3 bg-[#F6F6F6] border-gray-400 rounded focus:outline-none placeholder:italic"
                    value={link}
                    onChange={(e) => setLink(e.target.value)}
                />
            </div>

            {
                link.length > 0 &&
                <div className="flex flex-row justify-end w-full h-full overflow-hidden pt-2">
                    <button
                        onClick={async () => { config.handleScrapeUrl(link); setModalStatus("CLOSE"); }}
                        className={`flex min-w-48 justify-center p-2 text-black rounded-lg border border-gray-300 hover:text-emerald-500 hover:border-emerald-500 focus:outline-none bg-white`}
                    >
                        <div className="flex items-center gap-1"><span>Analyse link</span></div>
                    </button>
                </div>
            }
        </div>
    );
};