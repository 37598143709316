import { useState } from "react";
import { useChatGPT } from "../providers";
import { BookIcon, LinkIcon, ManageFileIcon, PaperclipIcon, ParamsIcon } from "./Icon";
import { SVGIcon } from "../../../assets/SVGIcon";
import { UploadDocuments } from "./MultiDocs/UploadDocuments";
import { ManageFiles } from "./MultiDocs/ManageFiles";
import { useMultiDocs } from "../providers/MultiDocsProvider";
import { AnalyseLinks } from "./MultiModal/AnalyseLinks";

const paramConfig = [
    {
        id: 0,
        title: "Analyse Links",
        icon: <SVGIcon size="18px" icon="LINKS_ICON" />,
        paramComponent: <AnalyseLinks />
    }
]


export const ChatGPTModalMultiModal = ({ }: {}) => {
    const {
        modalStatus,
        setModalStatus,
        gptConfig,
        gptModel
    } = useChatGPT();
    const { config } = useMultiDocs()

    const [appSelected, setAppSelected] = useState<Number>(0)

    return (
        <div className="w-[750px]">
            <div className="px-2 pb-2 pt-2 sm:p-4 flex items-center justify-between border-b border-black/10 dark:border-white/10">
                <div className="flex items-center gap-2">
                    <div className="flex items-center justify-center w-8 h-8 text-text-primary-light">
                        <SVGIcon size="18px" icon="LINKS_ICON" />
                    </div>
                    <h2 className="text-lg font-medium leading-6 text-text-primary-light font-trendaSemiBold">Multimodal - Analyse Links</h2>
                </div>
                <button className="flex h-8 w-8 items-center justify-center" onClick={() => setModalStatus("CLOSE")}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" className="icon-md">
                        <path fill="currentColor" fillRule="evenodd" d="M5.636 5.636a1 1 0 0 1 1.414 0l4.95 4.95 4.95-4.95a1 1 0 0 1 1.414 1.414L13.414 12l4.95 4.95a1 1 0 0 1-1.414 1.414L12 13.414l-4.95 4.95a1 1 0 0 1-1.414-1.414l4.95-4.95-4.95-4.95a1 1 0 0 1 0-1.414" clipRule="evenodd"></path>
                    </svg>
                </button>
            </div>
            <div className="flex">
                <div className="flex flex-col p-2 gap-2 content-center w-full">
                    <AnalyseLinks />
                </div>
            </div>
        </div>
    )
}