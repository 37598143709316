import { Ref, useEffect, useState } from "react";

export interface LineageKpisData {
    kpi_name: string;
    definition: string;
    file_path: string;
}

export interface NodeTable {
    [tableName: string]: string[];
}
  
export interface NodesData {
    [columnName: string]: NodeTable;
}
export interface NodeDetails {
    scope: string;
    category: string;
    kpi: string;
    table: string;
    column: string;
}

export interface Edge {
    source: NodeDetails;
    target: NodeDetails;
}

export interface EdgeTable {
    [tableName: string]: Edge[];
}

export interface EdgesData {
    [columnName: string]: EdgeTable;
}
  
  

export const useDataLineage = (token: string | null) => {
    const [dataLineage, setDataLineage] = useState<LineageKpisData[] | null>(null);
    const [loaderLineageData, setLoaderLineageData] = useState(false)
    const [nodes, setNodes] = useState<NodesData>({});
    const [edges, setEdges] = useState<EdgesData>({});


    const getLineageKpis = async(use_case:string) => {
        if (token === null) return;
        setLoaderLineageData(true)
        
        try {
            const response = await fetch(`/api/lineage/get_kpis?use_case=${use_case}`, {
              method: 'GET',
              headers: {
                'Authorization': `Bearer ${token}`
              }
            });
        
            if (!response.ok) {
              throw new Error(`Erreur HTTP: ${response.status}`);
            }
        
            const data = await response.json();
            setDataLineage(data.data)
        } catch (error) {
            console.error('An error occurred while fetching the ingestion data:', error);
            return null
        }
        finally{
            setLoaderLineageData(false)
        }
    }

    const getTables = async(filePath: string) => {
        if (token === null) return;
        setLoaderLineageData(true)
        
        try {
            const response = await fetch(`/api/lineage/tables?file_path=${filePath}`, {
              method: 'GET',
              headers: {
                'Authorization': `Bearer ${token}`
              }
            });
        
            if (!response.ok) {
              throw new Error(`Erreur HTTP: ${response.status}`);
            }
        
            const data = await response.json();
            setNodes(data)
        } catch (error) {
            console.error('An error occurred while fetching the ingestion data:', error);
            return null
        }
        finally{
            setLoaderLineageData(false)
        }
    }

    const getEdges = async(filePath: string) => {
        if (token === null) return;
        setLoaderLineageData(true)
        
        try {
            const response = await fetch(`/api/lineage/edges?file_path=${filePath}`, {
              method: 'GET',
              headers: {
                'Authorization': `Bearer ${token}`
              }
            });
        
            if (!response.ok) {
              throw new Error(`Erreur HTTP: ${response.status}`);
            }
        
            const data = await response.json();
            setEdges(data)
        } catch (error) {
            console.error('An error occurred while fetching the ingestion data:', error);
            return null
        }
        finally{
            setLoaderLineageData(false)
        }
    }

    return { getLineageKpis, getTables, getEdges, dataLineage, nodes, edges, loaderLineageData };
};