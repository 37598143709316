import React, { useState, useEffect, useRef, useSyncExternalStore } from "react";
import { models, service, factories, Embed } from 'powerbi-client';
import { useActiveTabs, useAuth } from "../../providers";
import { LoaderComponent } from "../../components";
import { v4 as uuidv4 } from 'uuid';
import { ChatGPT } from "../ChatGPT";
import { useToggle } from "@uidotdev/usehooks";
import { useChatGPT } from "../ChatGPT/providers";
import { Tabs } from "../../assets/Menu/menuData";
import { ModelGeminiIcon } from "../ChatGPT/components";
import { createSession, deleteSession, getSnapshot, subscribe } from "../../api/externalStore";


export const PowerBI = ({ report, id }: { report: string, id?: number }) => {
  const { token } = useAuth()
  const [loading, setLoading] = useState(true);
  const [sessionId, setSessionId] = useState<string>('');
  const configTimeRef = useRef<Date | null>(null);
  const hasRendered = useRef(false);
  const { closeTab } = useActiveTabs()
  const reportRef = useRef<HTMLDivElement>(null);
  const [isBootstrapped, setIsBootstrapped] = useState(false);

  const handleRenderComplete = () => {
    if (!hasRendered.current) {
      hasRendered.current = true;
      const now = new Date();
      setLoading(false);
      console.log(configTimeRef.current);
      if (configTimeRef.current) {
        fetch(`/api/dashboard/report-rendered`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({
            sessionId: sessionId,
            renderTime: (now.getTime() - configTimeRef.current.getTime()) / 1000  // Calculate render time in seconds
          })
        });
      }
    }
  };

  useEffect(() => {
    const newSessionId = uuidv4();
    setSessionId(newSessionId);

    fetch(`/api/dashboard/${report}/${newSessionId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((response) => response.json())
      .then(async (data) => {
        configTimeRef.current = new Date();
        const config = {
          type: "report",
          id: data.reports[0].id,
          embedUrl: data.reports[0].embedUrl,
          accessToken: data.token,
          tokenType: models.TokenType.Embed,
          settings: {
            filterPaneEnabled: false,
            navContentPaneEnabled: false,
          },
        }

        // Create a Power BI service instance
        const powerbi = new service.Service(
          factories.hpmFactory,
          factories.wpmpFactory,
          factories.routerFactory
        )

        // Bootstrap the report if not already bootstrapped
        if (reportRef.current && !isBootstrapped) {
          powerbi.bootstrap(reportRef.current, config);
          // Get the embed instance
          const embed = powerbi.get(reportRef.current) as Embed;

          // Add event handlers
          embed.off('loaded');
          embed.off('rendered');
          embed.off('error');

          embed.on('loaded', () => {
            console.log('Report loaded');
          });

          embed.on('rendered', () => {
            handleRenderComplete()
            console.log('Report rendered');
          });

          embed.on('error', (event) => {
            console.error('Error loading report:', event.detail);
          });

          setIsBootstrapped(true);
        }
      })
      .catch((error) => console.error("Error fetching data:", error))
  }, [report, token, isBootstrapped]);

  /*   useEffect(() => {
      const startTime = Date.now();
      const timeout = 2700000; // 45 minutes
  
      // Vérifie si requestIdleCallback est supporté
      const isIdleCallbackSupported = typeof requestIdleCallback !== 'undefined';
  
      // Fonction de vérification du temps écoulé
      function checkElapsedTime() {
        const elapsedTime = Date.now() - startTime;
  
        if (elapsedTime >= timeout) {
          if (id) {
            closeTab(id);
          }
        } else {
          if (isIdleCallbackSupported) {
            requestIdleCallback(checkElapsedTime);
          } else {
            setTimeout(checkElapsedTime, 60000); // Vérifie toutes les secondes
          }
        }
      }
  
      // Initialisation
      if (isIdleCallbackSupported) {
        requestIdleCallback(checkElapsedTime);
      } else {
        const timer = setTimeout(() => {
          if (id) {
            closeTab(id);
          }
        }, timeout);
  
        return () => clearTimeout(timer); // Nettoyage si le composant se démonte
      }
    }, [id, closeTab]); */
  const [sessionExpId, setSessionExpId] = useState<string | null>(null);
  
  const defaultStartTime = new Date().toISOString(); // Valeur initiale

  const startTime = useSyncExternalStore(
    subscribe,
    () => getSnapshot() || defaultStartTime // Retourne la valeur du store ou la valeur par défaut
  );

  useEffect(() => {
    // Crée une nouvelle session au montage
    if (token) {
      const initializeSession = async () => {
        const newSessionId = await createSession(token, 'dashboard');
        setSessionExpId(newSessionId);
      };
      initializeSession();
    }

  }, [token]);

  useEffect(() => {
    if (id && sessionExpId) {
      const interval = setInterval(() => {
        if (startTime) {
          const start = new Date(startTime); // startTime doit être une ISO string
          const now = new Date();
      
          const diff = now.getTime() - start.getTime();
          console.log("Start Time:", start.toISOString(), "Now:", now.toISOString(), "Diff:", diff);
          // Vérifie si 1 minute s'est écoulée
          if (diff > 35 * 60 * 1000) {
            console.log(`Session expired for ID: ${sessionExpId}`);
            deleteSession(sessionExpId, 'dashboard'); // Supprime la session
            setSessionExpId(null); // Nettoie l'état local
            closeTab(id); // Ferme l'onglet
          }
        } else {
          // Gère le cas où startTime est null ou None
          console.log(`Session not found or invalid for ID: ${sessionExpId}`);
          deleteSession(sessionExpId, 'dashboard'); // Supprime la session si invalide
          setSessionExpId(null); // Nettoie l'état local
          closeTab(id); // Ferme l'onglet
        }
      }, 30000);
  
      return () => clearInterval(interval); // Nettoyage de l'intervalle
    }
  }, [startTime, sessionExpId, id]);

  const { isSidebarOpen, toggleSidebar } = useActiveTabs()
  const { tabState, openTab } = useActiveTabs()
  const [dashboardOpen, setDashboardOpen] = useState<Tabs[]>([])
  useEffect(() => {
    setDashboardOpen([])
    tabState.tabs.forEach((tab) => {
      if (tab.id >= 10 && tab.id < 50) {
        setDashboardOpen(prevState => [...prevState, tab])
      }
    })
  }, [tabState])
  const { chatStatus, setChatStatus } = useChatGPT();

  const openAssistant = () => {
    if (isSidebarOpen) {
      toggleSidebar()
    }
    setChatStatus("ASSISTANT_OPEN")
  }

  return (
    <>
      <div className="flex w-full h-screen"> {/* Utilisation de h-screen pour occuper tout l'écran */}
        <LoaderComponent loadingConfig={!isBootstrapped && loading && startTime != null}>
          <></>
        </LoaderComponent>

        <div className="flex flex-col w-full h-full"> {/* h-full pour occuper toute la hauteur disponible */}

          <div className="flex h-7 w-full justify-between">
            <div className="flex w-10/12 overflow-auto no-scrollbar">
              {dashboardOpen.map((tab, index) => (
                <div
                  key={index}
                  onClick={() => { openTab(tab); setChatStatus("ASSISTANT_CLOSE") }}
                  className={`${tab.id == id ? "bg-text-primary-light text-white" : "text-text-primary-light"} hover:bg-text-primary-light hover:text-white flex items-center whitespace-nowrap cursor-pointer px-4`}
                >
                  <h3 className="font-trendaSemiBold text-xs">{tab.title}</h3>
                </div>
              ))}
            </div>
            {id == 11 && (
              <div className="flex w-2/12 justify-end">
                <div
                  onClick={chatStatus == "ASSISTANT_OPEN" ? undefined : () => openAssistant()}
                  className={`flex justify-center gap-2 ${chatStatus == "ASSISTANT_OPEN"
                    ? "bg-text-primary-light text-white"
                    : "text-text-primary-light"
                    } hover:bg-text-primary-light hover:text-white items-center py-1 px-2 whitespace-nowrap cursor-pointer`}
                >
                  <ModelGeminiIcon />
                  <h3 className="font-trendaSemiBold text-xs">Assistant AI</h3>
                  {chatStatus == "ASSISTANT_OPEN" && (
                    <div onClick={() => setChatStatus("ASSISTANT_CLOSE")}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        fill="none"
                        viewBox="0 0 24 24"
                        className="icon-md"
                      >
                        <path
                          fill="currentColor"
                          fillRule="evenodd"
                          d="M5.636 5.636a1 1 0 0 1 1.414 0l4.95 4.95 4.95-4.95a1 1 0 0 1 1.414 1.414L13.414 12l4.95 4.95a1 1 0 0 1-1.414 1.414L12 13.414l-4.95 4.95a1 1 0 0 1-1.414-1.414l4.95-4.95-4.95-4.95a1 1 0 0 1 0-1.414"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>


          <div className="flex w-full flex-grow overflow-auto"> {/* flex-grow et overflow-auto pour le défilement */}
            <div
              ref={reportRef}
              className={`report-container flex flex-col w-full items-center ${!(!isBootstrapped && loading) && "h-full"}`}
              id="capture-area"
            >
              {/* Contenu du rapport */}
            </div>
            {chatStatus == "ASSISTANT_OPEN" && (
              <div className="flex w-[512px] shadow-lg bg-primary-light border-l border-[#F6F6F6] rounded-l-lg">
                <ChatGPT />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PowerBI;
